.histogram {
	
	.histogram-axis {
		
		.domain {
			fill: none;
			stroke: @darkgrey;
		}
		
		.tick text {
			font-size: 10px;
		}
	}
	
	.histogram-bar {
		stroke: none;
	}
}

.chart-tooltip {
	padding: 3px;
	line-height: 16px;
	background-color: @darkgrey;
	text-align: center;
	font-size: 12px;
	color: white;
	pointer-events: none;
	opacity: 0.3;
	border: 1px solid grey;
}

.graph {
	
	.axis {
		
		.domain {
			fill: none;
			stroke: @darkgrey;
		}
		
		.tick text {
			font-size: 10px;
			fill: @darkgrey;
			stroke: none;
		}
	}
}
