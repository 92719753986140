
textarea.help-text-textarea {
	width: 400px;
	height: 400px;
}


.twoColumnLayout {
	
	 .left-container{
		float: left;
		position: relative;
		width: 50%;
	}
	
	.right-container {
		float: left;
		position: relative;
		width: 50%;
		padding-left: 10px;
		min-height: 80px;
	}
	
	&:after { content: "\00A0"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
}

div#help-text-form-container {
	.form-field-tooltip {
		display: none;
	}
}

div#help-text-markdown-preview-container {
	margin-top: 30px;
	background-color: @grey;
	padding: 10px;
}

div.email-config-form-container {
	
	margin-top: 20px;
	margin-bottom: 40px;
	
	.email-text-config-field {
		width: 600px;
	}
	
	textarea.email-text-config-field {
		height: 300px;
	}
}


.float-left-panel {
	.clearfix();

	> * {
		float: left;
	}
}

.plain-form-container {
	position: relative;
	margin-right: 40px;
	padding: 20px 20px 60px 20px;
	border: 1px solid grey;

	> h3 {
		margin-top: 0;
		margin-bottom: 50px;
	}

	> button {
		position: absolute;
		right: 20px;
		bottom: 20px;
	}

	> .button-panel {
		position: absolute;
		right: 20px;
		bottom: 20px;
		.clearfix();

		> button {
			float: right;
			margin-left: 20px;
		}
	}

	> form {
		> label {
			display: block;
			margin-bottom: 20px;

			> span:first-child {
				text-align: left;
			}
		}
	}
}

#backup-settings-form {
	display: inline-block;
	min-width: 600px;
}

table.partition-table {
	td {
		padding: 5px;
		padding-left: 10px;
	}
}

div.monitoring-info-item {
	width: 415px;
	background : @grey7;
	padding: 10px;
	margin-bottom: 8px;
	display: inline-block;
	vertical-align: top;
	min-height: 60px;

	.info-label {
		margin-bottom: 5px;
	}

	.progress-container {
		min-height: 20px;
	}
}
