/**
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */

.dialog-overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 59998; // below loader animation background and TinyMCE Menu/Toolbar
	height: 100%;
	
	overflow: auto;
	
	background-color: rgba(0, 0, 0, 0.7);
	
	-webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in;
}

.dialog {
	position: relative;
	width: 430px;
	margin: 10vh auto 0;
	background: @white;
	font-size: 14px;
	
	.jstree {
		overflow: auto;
		height: 20vh;
	}
	
	.dialog-head {
		height: 50px;
		line-height: 50px;
		background-color: lighten(@darkgrey, 10%);
		text-align: center;
		vertical-align: middle;
		
		.dialog-title {
			display: block /* so first-letter-uppercase works */;
			color: white;
			font-size: 18px;
			.first-letter-uppercase-mixin();
		}
	}
	
	.dialog-body {
		padding: 30px 45px;
		
		.dialog-content {

		}
		
		.dialog-button-panel {
			margin-top: 40px;
			text-align: center;
	
			&:after {
				content: '';
				display: block;
				clear: both;
			}
	
			.kru-button {
				min-width: 120px;
				font-size: 14px;
			}
			
			.ok-button {
				
			}
			
			.cancel-button {
				background-color: lighten(@darkgrey, 10%);
			}
		}
	}
}
