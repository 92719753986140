
.error-messages-container {

  .user-group {
    display: inline-block;
    padding: 10px;
    background: @grey7;
    min-width: 250px;
    text-align: center;
  }
}
