.user-select (@val) {
	user-select: @val;
}
.transition (@prop: all, @time: 1s, @ease: linear) {
	transition: @prop @time @ease;
}


.zeroOpacity{
	opacity:0;
}
.fullOpacity{
	opacity:1;
}
.displayNone{
	display:none;
}

*.hidden {
	display: none !important;
}