
.reversed-checkbox-fieldset {
	border: 2px solid @darkgrey;
	padding: 8px;

	legend {
		color: @darkgrey;
		padding: 5px;
		font-size: 1.2em;
		font-weight: bold;
	}

	label.checkbox-label {
		color: @darkgrey;
		display: inline-block;
	}

	input[type="checkbox"]:disabled + label.checkbox-label {
		color: @grey2;
	}
}
