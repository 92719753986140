
#pickElementTypeDialog {
	.kru-form-field-option-label {
		display: block;
		margin-top: 6px;
	}
}

div#measurementPointDialog,  div#sumDialog{
	input.clickable:not(.disabled) {
		cursor: pointer;
	}
}

div#formulaDialog {
	
	.formula-validation-result {
	
		.success {
			color : @green;
		}
		
		.pending {
			color : @darkgrey;
			background: url("/images/gif/spinner.png.gif") no-repeat;
			background-size: 1em 1em;
			padding-left: 17px;
		}
		
		.failure {
			color : @red;
		}
	}
}
