
div.kru-context-menu-container {
	position: fixed;
	transition: right 1s, top 1s;
	right: 0;
	top: 345px;
	z-index: 11; /* bigger than footer z-index 10 */
}

div.kru-context-menu-selector {
	position: absolute;
	top: @contextmenu_selector_top;
	width: 0px;
	right: 200px;
	min-height: 200px;
	border-right: 28px solid @color_contextmenu_background;
	border-bottom: 12px solid transparent;
	border-top: 12px solid transparent;
	cursor: pointer;
	transition : border 500ms ease-out;

	&:after {
		content: "";
		position: relative;
		width: 15px;
		height: 15px;
		top: @contextmenu_selector_after_top;
		left: 4px;
		border-color: @color_contextmenu_selector !important;
		&:extend(.arr);
		&:extend(.arr-right);
		
		border-width: @contextmenu_selector_after_border_width;
	}
}

div.kru-context-menu {
	position: absolute;
	width: 200px;
	min-height: 300px;
	right: 0;
	background-color: @color_contextmenu_background;
	transition: right 1s;
	
	ol {
		li {
			cursor: pointer;
			background-color: @color_contextmenu_item_background;
			font-size: 16px;
			line-height: 20px;
			color:@white;
			padding: 14px;
			transition: background 0.5s;
			margin: @contextmenu_item_margin;
			
			.first-letter-uppercase-mixin();
		}
		
		li:hover {
			background-color: @color_contextmenu_item_background_hover;
		}
		
		li.disabled {
			cursor: default;
			color : @grey5;
		}
		
		li.disabled:hover {
			background-color: @color_contextmenu_item_background;
		}
	}
}

div.kru-context-menu-container.folded {
	right: -200px;

	.kru-context-menu-selector {
		&:after {
			left: 12px;
			&:extend(.arr);
			&:extend(.arr-left);
		}
	}
}

@media screen and (min-width: 1660px){
	div.kru-context-menu-container.folded {
		right: 0px;
	}
	
	div.kru-context-menu-selector {
		border-right: 8px solid @color_contextmenu_background;
		cursor: default;
		pointer-events: none;
		&:after {
			border-color: @color_contextmenu_selector_small !important;
		}
	}
}

@media screen and (max-height: 1080px){
	div.kru-context-menu-container {
		top: 274px;
	}
}

@media screen and (max-height: 850px){
	div.kru-context-menu-container {
		top: 240px;
	}
}

@media screen and (max-height: 700px){
	div.kru-context-menu-container {
		top: 200px;
	}
}

@media screen and (max-height: 600px){
	div.kru-context-menu-container {
		top: 160px;
	}
}
