.kru-form-field-label .slider {
	display: inline-block;
	position: relative;
	width: 200px;
}

.slider {
	height: 40px;
	
	.track-container {
		position: relative;
		height: 20px;
		margin: 0 10px 0 10px;
		
		.track {
			position: absolute;
			top: 9px;
			left: 0;
			width: 100%;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.05);
		}
		
		.track-fill {
			position: absolute;
			top: 9px;
			left: 0;
			height: 4px;
			border-radius: 4px;
			background-color: @darkgrey;
		}
		
		.thumb {
			position: absolute;
			width: 20px;
			height: 20px;
			margin-left: -10px;
			border: 1px solid gray;
			border-radius: 100%;
			background-color: lightgray;
			cursor: pointer;
		}
		
		.value {
			display: inline-block;
			position: absolute;
			bottom: -18px;
			left: -90px;
			width: 200px;
			text-align: center;
			font-size: 12px;
			color: @darkgrey;
		}
	}
}

.slider:focus {
	
	.track-container {
		
		.thumb {
			background-color: @color_main_focus;
		}
	}
}

.slider.disabled {
	pointer-events: none;
	
	.track-container {
		
		.track-fill {
			background-color: lighten(@darkgrey, 30%);
		}
		
		.thumb {
			background-color: lighten(lightgray, 20%);
			border-color: lighten(gray, 20%);
			cursor: default;
		}
		
		.value {
			color: lighten(@darkgrey, 20%);
		}
	}
}
