
.kru-progress {
	background-color: @grey8;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
	height: 20px;
	width: 100%;
	overflow: hidden;
	text-align: center;
	position: relative;

	.progress-bar {
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
		color: @black;
		float: left;
		font-size: 12px;
		height: 100%;
		line-height: 20px;
		text-align: center;
		transition: width 0.6s ease 0s;
	}
	.progress-percent {
		position: absolute;
		text-align: center;
		left: 0;
		right: 0;
		line-height: 21px;
	}

	.progress-bar-fine {
		background-color: @color_main;
	}
}
