.object-heading-label {
	display: inline-block;
	font-size: 20px;
	font-weight: bold;
	color: @color_main;
	text-transform: uppercase;
}

.object-heading-value {
	font-size: 18px;
	font-weight: bold;
	color: @darkgrey;
}

#sub-content {
	position: relative;
	margin-top: 40px;
	min-height: 300px;
}
