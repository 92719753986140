.login-layout-container {
	height: auto;
	min-height: 100%;
	min-width: 532px;
	background: @color_main;
	overflow: hidden;

	div#layout-header {
		width: 100%;
		background: white;

		// this is here temporarely
		.session-box {
			display: none;
		}

		div.logo {
			left: 66px;
		}
	}

	div#layout-content {
		width: 100%;
		div#login-container {
			margin-top: 9%;
		}

		div#login-icon-gradient {
			position: relative;
			margin-left: auto ;
			margin-right: auto ;
			height: 52px;
			width: 106px;
			margin-top: -50px;
			transform: rotate(41deg);
			left: 37px;
			bottom: 26px;
			background: linear-gradient(90deg, @color_main 0%, @color_main_dark 42%, @color_main 100%);
		}

		div#login-icon {
			position: relative;
			border-radius: 50px;
			background-color: @darkgrey;
			width: 100px;
			height: 100px;
			text-align: center;
			margin-right: auto;
			margin-left: auto;
			z-index: 3;
			svg {
				width:46px;
				height: 46px;
				margin-top: 23px;
				color: @white
			}
		}

		div.login-box {
			position: relative;
			padding: 0;
			margin-left: auto ;
			margin-right: auto ;
			height: 370px;
			width: 330px;
			div.content {
				position: relative;
				height: 100%;
//				width: 330px;
				background-color: @grey;
//				margin-left: auto ;
//				margin-right: auto ;
				border-radius: @border-radius;
				padding: 30px 20px 10px 20px;
				margin-top: -38px;
				margin-bottom: 0;
				z-index: 2;
				p {
					font-size: 20px;
					color: @grey5;
					text-align: center;
					padding-top: 46px;
					padding-bottom: 35px;
				}

				form {
					input, button {
						margin-bottom: 20px;
						width: 100%;
						height: 40px;
						color: @darkgrey;
						background-color: @grey2;
						border: none;
						border-radius: @border-radius;
						padding: 10px;
						text-align: center;
					}

					input:focus {
						background-color: @color_main_focus;
					}

					button {
						background-color: @color_main;
						color: @white;
						text-transform: uppercase;
						cursor: pointer;
						margin-bottom: 29px;
						height: 50px;
						transition: 4s;
						&.login-failed {
							transition: 0.3s;
							background-color: red;
						}
						
					}
				}

				a {
					color: @darkgrey;
					font-size: 14px;
					font-style: italic;

				}
			}

			&::before {
				content: "";
				position: absolute;
				height: 495px;
				width: 434px;
				background: linear-gradient(90deg, @color_main_dark 1%,@color_main 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				margin-left: auto ;
				margin-right: auto ;
				top: 80px;
				left: 114px;
				transform: rotate(41deg);
				z-index: 1;
			}
		}
	}
}

#layout-footer.layout-login-footer {
	background: transparent;
}
