dl.info-list {
	width: 400px;
	color: @darkgrey;
	font-size: 14px;
	
	&:after {
		content: "\00A0";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
	
	dt, dd {
		display: block;
		float: left;
		width: 50%;
		margin-bottom: 10px;
	}
	
	dt {
		clear: both;
		.first-letter-uppercase-mixin();
	}
}
