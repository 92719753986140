h1, h2, h3, h4 {
	font-weight: bold;
	color: @darkgrey;
}

h1 {
	margin-top: 30px;
	margin-bottom: 14px;
	font-size: 24px;
	text-transform: uppercase;
}

h2 {
	margin-top: 27px;
	margin-bottom: 12px;
	font-size: 20px;
	text-transform: uppercase;
}

h3 {
	margin-top: 25px;
	margin-bottom: 10px;
	font-size: 16px;
	text-transform: uppercase;
}

h4 {
	margin-bottom: 8px;
	font-size: 14px;
	.first-letter-uppercase-mixin();
}
