.select2-source.small-select, .small-select {
	width: 100px;
}

.select2-source {
	width: 200px;
	height: 28px;
	visibility: hidden;
}

select.kru-form-field {
	width: 200px;
}

span.select2-selection {
	border-radius: 0 !important;
	background-color: @grey6 !important;
	color: @darkgrey !important;
}

span.select2-results li.select2-results__option--highlighted[aria-selected] {
	background-color: @color_main_hover;
}

span.select2-container--open {
	z-index: 9999999;
}

.select2-container--focus:not(.select2-container--disabled), .select2-container--open:not(.select2-container--disabled) {
	span.select2-selection {
		background-color: @color_main_focus !important;
	}
}

select.empty-and-required + .select2-container, select.invalid + .select2-container {
	span.select2-selection {
		border-color: red !important;
	}
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #a0a0a0 !important;
}

.select2-container--disabled {
	pointer-events: none;

	.select2-selection__rendered {
		color: @grey3 !important;
	}
}

.select2-results__option {
	.first-letter-uppercase-mixin();
}

.kru-form-select {
	height: 26px;
	border: 1px solid @grey6;
	background-color: @grey6;
	color: @darkgrey;
	
	&:focus option {
		background-color: @color_main_focus;
	}
}

optgroup, .select2-results__group {
	font-weight: bold;
}

