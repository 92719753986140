/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */

@time_input_height: 30px;
@time-button-size: 20px;

.datetime-picker {
}

.datetime-input {
}


.datetime-popup {
	position: absolute;
	top: 30px; // TODO: nicht gut
	width: 250px;
	padding: 10px;
	z-index: 100;
	border: 1px solid @grey6;
	background-color: @grey8;
	color: @darkgrey;
	
	.moment-picker-calendar {
		margin-bottom: 20px;
	}
	
	.time-selection {
		width: 40%;
		margin-left: 30%;
		margin-bottom: 20px;
	}
	
	.hour-selection, .minute-selection {
		display: block;
		float: left;
		width: 45%;
	}
	
	.time-seperator {
		display: block;
		float: left;
		width: 10%;
		height: @time-button-size + @time_input_height + @time-button-size;
		line-height: @time-button-size + @time_input_height + @time-button-size;
		text-align: center; 
		font-size: 16px;
		font-weight: bold;
	}
	
	.hour-input, .minute-input {
		width: 100%;
		height: @time_input_height;
		line-height: @time_input_height;
		border: none;
		background-color: @grey6;
		text-align: center;
		font-size: 16px;
		color: @darkgrey;
	}
	
	.time-button {
		width: @time-button-size;
		height: @time-button-size;
		margin: auto;
		cursor: pointer;
	}
}

.datetime-popup.hidden {
	display: none;
}

.datetime-popup-buttons > * {
	float: right;
	margin-left: 20px;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
}

.datetime-popup-buttons > .ok-button {
	color: @color_datetimepicker_ok_button;
}

.moment-picker {
	width: 100%;
}

.moment-picker table {
	width: 100%;
}

.moment-picker-year-select, .moment-picker-month-select {
	width: 50%;
	font-size: 16px;
}

.moment-picker-calendar {
	margin-top: 20px;
}

.moment-picker-calendar th {
	font-weight: bold;
	padding-bottom: 4px;
}

.moment-picker-calendar td {
	width: 14.285%;
	height: 20px;
	text-align: center;
	vertical-align: middle;
}

.moment-picker-calendar-cell {
	cursor: pointer;
}

.moment-picker-calendar-cell-disabled {
	color: @grey4;
	cursor: default;
}

.moment-picker-calendar-cell-other-month {
	color: @grey2;
}

.moment-picker-calendar-cell-selected {
	background-color: @color_datetimepicker_selected_background;
	color: @color_datetimepicker_selected_text;
}
