.invoice-item-group {
	margin-bottom: 10px;
	vertical-align: middle;
	&.selected {
		background-color: @color_main !important;
	}
	&:hover {
		background-color: @grey8;
	}
}


.invoice-item {
	padding-top: 10px;
	padding-bottom: 10px;
	vertical-align: middle;
	&.selected {
		background-color: @color_main !important;
	}
	&:hover {
		background-color: @grey7;
	}
}

.item-order {
	position: absolute;
	right: -40px;
	display: none;
	div {
		position: relative;
		top:-30px;
	}
}
