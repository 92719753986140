.kru-form {
	h3 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 20px;
	}
}

.form-row {
	padding-bottom: 16px;

	.kru-form-input {
		height: 26px;
	}
}

.kru-form-field-label {
	display: block;
}

.kru-form-field-label-text {
	display: inline-block;
	width: 140px;
	/*height: 26px;
	line-height: 26px;*/
	padding-right: 14px;
	padding-top: 6px;
	text-align: right;
	vertical-align: top;
	color: @darkgrey;
	.first-letter-uppercase-mixin();
}

/* Common Field Style */

.kru-form-field.disabled, input:disabled {
	color: @grey3 !important;
}

.kru-form-input.empty-and-required, .kru-form-input.invalid,
.kru-form-select.empty-and-required, .kru-form-select.invalid,
.kru-form-textarea.empty-and-required, .kru-form-textarea.invalid {
	border-color: red;
}

/* Specific Field Style */

.kru-form-input, .kru-form-textarea {
	border: 1px solid @grey6;
	color: @darkgrey;
}

.kru-form-textarea {
	resize: vertical;
}

.kru-form-radio {
	display: inline-block;
	width: 200px;
	
	label {
		display: block;
		margin-bottom: 4px;
		
		> span {
			display: inline-block;
			margin-left: 10px;
			color: @darkgrey;
		}
		
		input:not(:disabled) {
			cursor: pointer;
		}
		
		input:focus + span {
			background-color: @color_main_focus;
		}
	}
}

input[type="color"] {
	box-shadow: none;
	
	&:not(:disabled) {
		cursor: pointer;
	}
}

input[type="file"] {
	/*border: 1px solid transparent;*/
	&.invalid, &.empty-and-required  {
		&:not(:disabled) {
			border-color: red;
			color: red;
		}
	}
}

.kru-form-field-label.datetime-picker {
	position: relative;
	
	.datetime-popup {
		left: 140px;
		top: 30px;
	}
	
	.form-field-tooltip {
		visibility: hidden;
	}
}

.datapoint-field {
	width: 200px;
	
	.items {
		padding-bottom: 16px !important;
	}

	&.empty-and-required, &.invalid {
		.select2-selection {
			border-color: red;
		}
	}
}

/* Not used in this Project maybe from another Module? */

.form-info-text {
	span {
		font-weight: bold;
	}
	font-size: 12px;
	color: @darkgrey;
}

.tobigmessage {
	display: none;
}

.kru-form .filetobig {
	.tobigmessage {
		display: inline-block;
		color: @red;
	}
}

.form-row.hidden-label-text {
	.kru-form-field-label-text {
		display: none;
	}
}

.icon-select {
	display: inline-block;
	position: relative;
	width: 200px;;
	height: 26px;
	vertical-align: middle;

	&.disabled {
		pointer-events: none;

		> img {
			opacity: 0.6;
		}
	}

	> img {
		height: 26px;
		width: 26px;
	}

	> input {
		height: 26px;
	}

	.dropdown {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		z-index: 100;
		border: 1px solid @grey4;
		background-color: white;

		ul {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			padding: 5px;

			li {
				width: 20%;
				padding: 2px;
				border: 1px solid #e1e1e1;
				border-radius: 3px;

				img {
					width: 100%;
					height: 100%;
				}

				&:hover {
					border-color: @color_main_hover;
					background-color: lighten(@color_main_hover, 20%);
				}

				&.selected {
					border-color: @color_main;
					background-color: lighten(@color_main, 20%);
				}
			}
		}
	}
}

.multi-checkbox-panel {
	display: inline-block;
	border: 1px solid @grey4;

	ul {
		width: 198px;;
		height: 104px;
		padding: 4px;
		overflow-x: hidden;
		overflow-y: scroll;

		> li {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 2px;
			}

			label {
				display: inline-block;
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				cursor: default;
			}
		}

		/*border-bottom: 1px solid @grey4;*/
	}

	button {
		width: 100%;
		margin-top: 10px;
	}
}
