
.kru-objectpicker-selection-display {
	display: block;
	margin-top: 20px;
	text-align: right;
}


table.kru-objectpicker-table {
	width: 100%;

	thead, tbody, tr, td, th { display: block; }

	tr {
		height: auto !important;
		min-height: 25px;
	}

	tr:after {
		content: ' ';
		display: block;
		visibility: hidden;
		clear: both;
	}

	tbody {
		overflow-y: auto;
	}

	tbody td, thead th {
		float: left;
	}

	tbody>tr:last-child {
		border-bottom-style: none;
	}
}

.kru-objectpicker-list {
	min-height: 150px;
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.datapoint-picker-row {
	
	td {
		font-size: 13px;
	}
	
	td > * {
		vertical-align: middle;
	}
	
	img.type-icon {
		margin-right: 5px;
	}
	
	cursor: pointer;
	
}
