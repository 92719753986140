@defaultTooltipBackgroundColor: @darkgrey;
@defaultTooltipBorderColor: @grey4;

.tooltip-parent {
	position: relative;
}

.tooltip-parent:hover .tooltip {
	z-index: 10;
	opacity: 1;
	transition: opacity 0.5s linear;
}

.tooltip-parent .tooltip {
	display: inline;
	position: absolute;
	z-index: -1000;
	opacity: 0;
	transition: opacity 0.5s linear, z-index 0s linear 0.5s;
	
	// Nur Style, muss nicht zwingend hier rein.
	padding: 5px;
	border: 1px solid @defaultTooltipBorderColor;
	background: @defaultTooltipBackgroundColor;
	text-align: left;
	line-height: 1.2;
	color: @white;
}

.tooltip-nose-top-mixin(@tooltipNoseSize, @tooltipBackgroundColor) {
	&:after {
		content: " ";
		position: absolute;
		left: 50%;
		top: -@tooltipNoseSize;
		width: 0;
		height: 0;
		margin-left: -@tooltipNoseSize;
		border-left: solid transparent @tooltipNoseSize;
		border-right: solid transparent @tooltipNoseSize;
		border-bottom: solid @tooltipBackgroundColor @tooltipNoseSize;
	}
}

.tooltip-nose-bottom-mixin(@tooltipNoseSize, @tooltipBackgroundColor) {
	&:after {
		content: " ";
		position: absolute;
		left: 50%;
		bottom: -@tooltipNoseSize;
		width: 0;
		height: 0;
		margin-left: -@tooltipNoseSize;
		border-left: solid transparent @tooltipNoseSize;
		border-right: solid transparent @tooltipNoseSize;
		border-top: solid @tooltipBackgroundColor @tooltipNoseSize;
	}
}

.tooltip-nose-left-mixin(@tooltipNoseSize, @tooltipBackgroundColor) {
	&:after {
		content: " ";
		position: absolute;
		top: 50%;
		left: -@tooltipNoseSize;
		width: 0;
		height: 0;
		right-left: -@tooltipNoseSize;
		border-bottom: solid transparent @tooltipNoseSize;
		border-right: solid @tooltipBackgroundColor @tooltipNoseSize;
		border-top: solid transparent @tooltipNoseSize;
	}
}
