#time-selection {
	color: @darkgrey;
	
	/*label {
		display: block;
		margin: 2px 0 2px 0;
		
		span {
			display: inline-block;
			width: 50px;
		}
	}*/
}

#chart-table {
	th {
		width: 25%;
	}
}

.interval-dialog {	
	
	b {
		display: inline-block;
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 2px;
		color: @darkgrey;
		.first-letter-uppercase-mixin();
	}
	
	.interval-row {
		margin-bottom: 2px;
		
		label {
			display: inline-block;
			position: relative;
			margin-right: 5px;
			
			input {
				height: 25px;
			}
		}
		
		button {
			position: relative;
			top: -1px;
			height: 25px;
		}
	}
	
	> button {
		display: block;
	}
}

.charting {
	
	.content-head {
		height: 28px;
		
		> * {
			height: 100% !important;
		}
		
		.select2 {
			float: left;
		}
		
		.favinfo-table {
			margin-left: 10px;
			float: left;
			td {
				&:first-child {
					padding-right: 10px;
				}
				margin: auto;
			}
			.name-display {
				text-align: center;
				font-weight: bold;
			}
		}
		
		.toolbar {
			float: right;
		}
	}

	#chart-container {
		margin-top: 10px;
	}
}

#toolbar-container {
	> * {
		height: 100%;
	}
}

/*
 * Standalone charts on a blank page e.g. for PhantomJS
 */
.charting-standalone {
	#toolbar-container {
		/* hide interactive stuff */
		display: none;
	}

	#chart-container {
	}
	
	#legend-container {
	}

	#info-headline-container {
		tr {
			height: 40px;

			th {
				vertical-align: middle;
			}

			th.main-period-col {
				text-align: right;
			}
		}
	}
}
