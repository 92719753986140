
button, input[type="submit"], .kru-button {
	background-color: @color_main;
	background-repeat: no-repeat;
	display: inline-block;
	cursor: pointer;
	-webkit-transition: background-color 0.5s; /* For Safari 3.1 to 6.0 */
	transition: background-color 0.5s;
	text-decoration: none;
	padding: 5px 13px;
	color: @color_kru_button_text;
	font-size: 12px;
	font-family: sans-serif;
	border: none;
	outline:none;
	text-align: center;
	-moz-user-select: none;
	
	.first-letter-uppercase-mixin();
	
	&:active {
		background-color: lighten(@color_main_hover, 10);
		padding: 5px 13px;
	}
	
	&:hover {
		background-color: darken(@color_main, 5);
	}
	
	&:disabled {
		background-color: lighten(@color_main, 10);
		color: darken(@color_main, 10);
		cursor: default;
	}
	
	&:focus {
		padding: 5px 13px;
	}
	
	&.big {
		height : 40px;
		font-size: 17px;
		line-height: 1.7;
	}
	
	&.block {
		display: block;
		width: 100%;
	}
	
	&.icon {
		padding-left: 25px;
		
		&:active, &:focus {
			padding-left: 25px;
		}
	}
}
