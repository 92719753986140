.markdown 
{
	em {
		font-style: italic;
	}
	
	strong {
		font-weight: bold;
	}
	
	ul, ol {
		margin-top: 8px;
		margin-bottom: 8px;
	}
	
	ol {
		li {
			list-style: decimal;
			margin-left: 20px;
		}
	}
	
	ul {
		li {
			list-style: disc;
			margin-left: 20px;
		}
	}
	
	code {
		font-family: 'Fira Mono', monospace;
	}
	
	p {
		margin-top: 1em;
		margin-bottom: 1em;
	}
}
