
@menu-height : 100px;
@menu-height-small : 60px;
@menu-height-mobile : 40px;
@menu-width-mobile: 60vmin;
@footer-height : 40px;
@page-width : 1260px;
@border-radius : 5px;

@content-widget-head-bar-height: 30px;
@content-widget-head-control-height: 20px;
@headline-font-size: 25px;

html, body {
	margin: 0;
	padding: 0;
}

#layout-container {
	min-height: 100vh;
	margin: 0;
	padding: 0;
}

body > #layout-container {
	height: auto;
	overflow: hidden;
}

*:focus {
	outline: none;
}

body {
	color: @darkgrey;
}

#layout-header {
	position: relative;
	height: 120px;
	.layout-content-width;

	.logo {
		position: absolute;
		left: 0px;
		top:20px;
		background: @logo_url;
		width: 400px;
		height: 80px;
	}
	
	.session-info {
		position: absolute;
		top:20px;
		height: 80px;
		right: 0px;
	}

	.session-logged-in{
		position: absolute;
		top: 38px;
	}

	span#login-name {
		position: relative;
		bottom: 18px;
		margin-right: 35px;
		color: @darkgrey;
		font-weight: 500;
	}

	div.unread-messages-counter {
		position: absolute;
		width: 26px;
		height: 26px;
		background: @color_unread-messages-counter;
		border-radius: 50px;
		bottom: 60px;
		right: 100px;
		text-align: center;
		padding-top: 5px;
		color: white;
		font-weight: 500;
		font-size: 16px;
		
		&.position-1 {
			right: 25px;
		}
		&.position-2 {
			right: 107px;
		}
		&.position-3 {
			right: 190px;
		}
	}

	.session-box a:first-child > svg {
		margin-right: 15px;
	}

	.session-box-icons {
		width: 45px;
		height: 45px;
		color: @darkgrey;
		margin-right: 35px;
	}

	.session-box-icons:hover {
		color: @grey3;
	}
}

#layout-menu {
	background: @color_main;
	height: @menu-height;
	min-width: @page-width;
	margin-bottom: 10px;

	&.has-horizontal-bar {
		margin-bottom: 2px;
	}

	.container {
		.layout-content-width();
		//height: @menu-height;
		position: relative;
		z-index: 49998;

		.menu {
			display: inline-block;
			
			&.collapsable {
				
				.item-container {
					transition: opacity 500ms;
				}
				
				.item-row {
					height: @menu-height;
				}
			}
		}

		.item {
			display: inline-block;
			width: 100px;
			height: 100px;
			background: @color_main;
			transition: background 0.1s linear 0s;
			
			svg {
				width:60px;
				height: 60px;
				margin:20px;
			}
			
		}
		.item:hover {
			background: @color_main_dark;
		}
		
		.item-container {
			height: @menu-height;
		}
		&.menu-horizontal-display .item-container {
			display: inline;
		}

		.item.active {
			background: @color_main_hover;
		}
		
		div.label {
			color: @white;
			font-size: 34px;
			padding-top: 32px;
			padding-left: 32px;
			display: inline-block;
			vertical-align: top;
			height: @menu-height;
			&::first-letter {
				text-transform: uppercase;
			}
		}

		div.dateSelector {
			background-color: @darkgrey;
			width: 350px;
			display: inline-block;
			float: right;

			div.clock {
				background-color: @color_main;
				padding-bottom: 7px;
				padding-left: 20px;
				height: @menu-height;
				padding-top: (@menu-height - 32px);
				span {
					cursor: pointer;
					vertical-align: top;
					display: inline-block;
					padding-left: 8px;
					padding-top: 6px;
					color: @color_dateselector_clocktext;
					font-size: 14px;
					.user-select(none);
				}
				svg {
					width:25px;
					height: 25px;
					color: @color_dateselector_clocktext;
				}
			}

			div.dateSelectorBar {
				background-color: @color_dateselector;
				height: 52px;
				.transition(height, 100ms);
				a.date-selector-item {
					svg {
						width:30px;
						height: 30px;
						margin:10px;
					}
				}

				a.date-selector-item:hover {
					svg {
						color: @color_date-bar-item-selected;
					}
				}

				a.date-bar-item-selected {
					cursor: default;
					svg {
						color: @color_date-bar-item-selected;
					}
				}
			}

			div.bar-collapsed {
				height: 11px;
			}

			span#display-mode {
				color: @color_dateselector_text;
				width: 140px;
				vertical-align: top;
				display: inline-block;
				padding: 20px;
				font-size: 14px;
				.user-select(none);
				cursor: default;
			}
		}

	}
}

div#layout-menu-horizontal-bar{
	background: linear-gradient(to right, white 50% , @color_submenu_background 50%);
}

div.horizontal-bar{
	.layout-content-width();
	background-color: @color_submenu_background;
	position: relative;
	left: 100px;
	height: 40px;
	margin-bottom: 10px;

	ul {
		li {
			display: inline;
			&.active a {
				background-color: @color_submenu_background_active;
			}
		}

		a {
			font-size: 18px;
			color: white;
			background-color: @color_submenu_background;
			height: 40px;
			padding: 10px;
			display: inline-block;

			&:hover {
				background-color: @color_submenu_background_hover;
			}
		}
	}
}

#layout-footer {
	background: @color_footer_background;
	clear: both;
	position: relative;
	z-index: 10;
	height: @footer-height;
	margin-top: -@footer-height;
	vertical-align: top;
	min-width: 532px;
	.content {
		text-align:center;
		padding-top: 7px;

		.flag when (@show_swiss_emblem = true) {
			display:inline-block;
			width: 24px;
			height: 26px;
			background: url('/images/schweizer_wappen.svg');
			background-size: 24px 26px;
		}
		
		.flag when not (@show_swiss_emblem = true) {
			display: none;
		}

		.text {
			display:inline-block;
			height: 26px;
			color: @white;
			vertical-align: top;
			font-size: 15px;
			padding-top: 4px;
		}
	}
}

#layout-content {
	.layout-content-width();
	padding-bottom: calc(@footer-height + 10);
	& > div:first-child {
		margin-top: 56px;
		&.breadcrumb {
			margin-top: auto;
		}
	}
}
.treelayout {
	
	 .tree-container{
		float: left;
		position: relative;
		width: 30%;
	}
	.jstree	{
		height: 65vh;
		overflow-y: auto; 
		min-height: 80px;
	}
	
	.content-container {
		float: left;
		position: relative;
		width: 70%;
		padding-left: 10px;
		color: @darkgrey;
		min-height: 80px;
	}
	
	&:after { content: "\00A0"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
}

.layout-content-width {
	margin: 0px auto;
	width: @page-width;
}

/* svg-links */
object[data$=".svg"] {
  pointer-events: none;
}

.clearfix:after { content: "\00A0"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
.clearfix{ display: inline-block;}
html[xmlns] .clearfix { display: block;}
* html .clearfix{ height: 1%;}
.clearfix {display: block}

.clearfix-mixin() {
	:after {
		content: "\00A0";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
}

.center {
	text-align: center;
	margin: auto;
}

.vertCentered {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.horizCentered {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.bothCentered {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.loading-spinner {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	/*border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.05);*/
	
	.loading-spinner-icon {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 50px;
		height: 50px;
		transform: translate(-50%, -50%);
		background-image: url("/images/gif/spinner.png.gif");
		background-size: 100%, 100%;
	}
	
	.loading-spinner-text {
		display: none;
		position: absolute;
		left: 50%;
		top: 50%;
		color: white;
		font-size: 30px;
		transform: translate(-50%, -50%);
	}
}

.capitalize, .capitalize * {
	text-transform: capitalize;
}

.first-letter-uppercase, .first-letter-uppercase * {
	&:first-letter {
		text-transform: uppercase;
	}
}

.first-letter-uppercase-mixin {
	&:first-letter {
		text-transform: uppercase;
	}
}

.relative {
	position: relative;
}

.return-to-overview-link {
	color: @color_content_head;
	.first-letter-uppercase-mixin();
}

.template {
	display: none;
}

.error {
	color: red;
}

.warning {
	color: orange;
}

.success {
	color: green;
}
