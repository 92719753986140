.virtual-meter-connection-table, .lo-ra-value-config-dialog table {
	border-left: 1px solid @darkgrey;
	border-right: 1px solid @darkgrey;
	
	thead {
		tr {
			// so head does not resize when svgs are loaded
			height: 30px;
		}
		
		th {
			text-align: center !important;
			padding: 0 !important;
			
			object {
				width: 25px;
				height: 25px;
				margin-top: 2px;
			}
		}
	}
	
	tr {
		border-bottom: 1px solid @darkgrey;
	}
	
	tbody {
		td {
			padding: 4px 4px 4px 4px !important;
			cursor: default;
		}
		
		td.first-column-cell {
			text-align: left;
		}
		
		td.factor-cell, td.factor-sum-cell {
			text-align: center;
			input {
				text-align: right;
			}
		}
		
		td.factor-divided-evenly-cell {
			text-align: center;
			
			label {
				position: relative; 
				left: 3.7px;
			}
		}
		
		.factor-sum-text-field {
			cursor: default;
			pointer-events: none;
		}
		
		.heading-cell {
			.first-letter-uppercase-mixin();
		}
	}
	
	.info-symbol {
		@fontSize: 16px;
		.info-symbol-mixin(@fontSize);

		.tooltip {
			@width: 320px;
			@noseSize: 10px;

			left: -@width / 2 + @fontSize / 2;
			top: @fontSize + @noseSize;
			width: @width;
			font-size: 12px;
			pointer-events: none;
			
			.tooltip-nose-top-mixin(@noseSize, @defaultTooltipBackgroundColor);
		}
	}
	
	input, select {
		width: 55px;
		padding-right: 4px;
		padding-left: 4px;
		border: 1px solid gray;
		color: @darkgrey;
	}
	
	
}

.edit-virtual-meter-connections-dialog {
	width: 800px;
}

.info-symbol-mixin(@fontSize) {
	display: inline-block;
	width: @fontSize;
	height: @fontSize;
	text-align: center;
	vertical-align: middle;
	border: 1px solid @darkgrey;
	border-radius: @fontSize / 2;
	font-size: @fontSize * 0.8;
	
	font-weight: bold;
	cursor: default;
}

table.element-template-table {
	> tbody {
		> tr {
			> td:first-child {
				width: 40px;
				img {
					height: 20px;
				}
			}
		}
	}
}

.element-template-form {
	input[type=file].kru-form-field {
		width: 120px;
		border: 1px solid transparent;
		color: transparent;

		&.invalid, &.empty-and-required {
			border-color: @red;
		}
	}
}
