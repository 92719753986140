
@breadcrumb-fontsize:         13px;
@breadcrumb-margin:           10px;
@breadcrumb-height:           26px;
@breadcrumb-arrow-width:      10px;
@breadcrumb-arrow-space:       2px;
@breadcrumb-tooltipp-width:  350px;
@breadcrumb-tooltipp-arrow-h: 10px;
@breadcrumb-tooltipp-arrow-w: 20px;

ul.breadcrumb {
	
	margin-top: @breadcrumb-margin;
	margin-bottom: @breadcrumb-margin;
	display:inline-block;
	
	li {
		display: inline-block;
		height: @breadcrumb-height;
		padding: 0 0;
		font-size: @breadcrumb-fontsize;
	}
	
	li + li {
		margin-left: -@breadcrumb-arrow-width + @breadcrumb-arrow-space;
	}
	
	li + li::before {
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-left: @breadcrumb-arrow-width solid transparent;
		border-top: @breadcrumb-height/2 solid @color_content_head;
		border-bottom: @breadcrumb-height/2 solid @color_content_head;
		vertical-align: middle;
	}
	
	li::after {
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-left: @breadcrumb-arrow-width solid @color_content_head;
		border-top: @breadcrumb-height/2 solid transparent;
		border-bottom: @breadcrumb-height/2 solid transparent;
		vertical-align: middle;
	}
	
	li span {
		background: @color_content_head;
		color: @white;
		padding: @breadcrumb-margin/2 @breadcrumb-margin;
		line-height: @breadcrumb-height - @breadcrumb-margin;
		vertical-align: middle;
		display: inline-block;
	}
	
	li:first-child span {
		padding-left: 15px;
	}
}

.breadcrumb + div.tooltipp {
	display: inline-block;
	position: relative;
	margin-left: @breadcrumb-margin;
	vertical-align: middle;
	width: @breadcrumb-height; 
	height: @breadcrumb-height;
	font-size: @breadcrumb-fontsize;
	border: 1px solid @color_content_head;
	border-radius: @breadcrumb-height/2;
	
	span {
		position: relative;
		right: 0.1ex;
		bottom: 0.1ex;
		color: @color_content_head;
		font-family: "Times New Roman", Serif;
		font-size: @breadcrumb-height;
		vertical-align: middle;
		display: inline-block;
		padding: 0 @breadcrumb-margin;
		line-height: @breadcrumb-height;
	}
	
	div.container {
		z-index: -1000;
		top: @breadcrumb-height + @breadcrumb-tooltipp-arrow-h - 2px;
		left: -(@breadcrumb-tooltipp-width / 2) + (@breadcrumb-tooltipp-arrow-w / 2);
		width: @breadcrumb-tooltipp-width;
		line-height:16px;
		opacity: 0;
		transition: opacity 0.5s linear, z-index 0s linear 0.5s;
		display:inline;
		position:absolute;
		background: @color_content_head;
		border: 1px solid @grey4;
		color: @white;
		
		div.arrow-up {
			z-index:20;
			position:absolute;
			border:0;
			top:-(@breadcrumb-tooltipp-arrow-h);
			left: (@breadcrumb-tooltipp-width / 2) - (@breadcrumb-tooltipp-arrow-w / 2);
			width: 0;
			height: 0;
			border-left: (@breadcrumb-tooltipp-arrow-w/2) solid transparent;
			border-right: (@breadcrumb-tooltipp-arrow-w/2) solid transparent;	
			border-bottom: (@breadcrumb-tooltipp-arrow-w/2) solid @color_content_head;
		}
		
		div.title {
			padding:6px 10px;
			background: @grey3;
			font-weight: bold;
		}
		
		div.content {
			padding:5px 10px;
			
			label {
				width: 35%;
				display: inline-block;
				font-weight: bold;
				margin-right: 10px;
			}
		}
	}
}

.breadcrumb + div.tooltipp:hover div.container {
	opacity: 1;
	z-index: 10;
	transition: opacity 0.5s linear;
}
