
@media screen and (max-height: 1080px){
	
	#layout-header {
		height: 90px;
		
		.logo {
			top: 5px;
		}
		
		.session-logged-in{
			top: 25px;
		}
		
		.session-box-icons {
			width: 40px;
			height: 40px;
		}
		
		div.unread-messages-counter {
			&.position-1 {
				right: 20px;
			}
			&.position-2 {
				right: 97px;
			}
			&.position-3 {
				right: 175px;
			}
		}
	}
	
	#layout-menu {
		height: @menu-height-small;
		.container {
			a.item {
				width: @menu-height-small;
				height: @menu-height-small;
				
				svg {
					width:40px;
					height: 40px;
					margin:10px;
				}
			}
			.menu.collapsable .item-row {
				height: @menu-height-small;
			}
			
			div.label {
				font-size: 24px;
				padding-top: 20px;
				height: @menu-height-small;
			}

			div.dateSelector {
				div.bar-collapsed {
					height: 10px;
				}
				div.clock {
					height: @menu-height-small;
				padding-top: (@menu-height-small - 32px);
				}
			}
		}
	}

	div.horizontal-bar {
		left: 60px;
	}
	
	/*.tenant-content-widget {
		@content-widget-height: 275px;
		@head-icon-size: 50px;
		@headbar-offset: -7px;
		@content-widget-header-bar-height: 30px;
		@headline-font-size: 20px;

		height: @content-widget-height;

		.head-icon {
			width: @head-icon-size;
			height: @head-icon-size
		}

		.head-bar {
			width: calc(~"100%" - @head-icon-size + @headbar-offset);
			height: @content-widget-header-bar-height;

			.headline{
				font-size: @headline-font-size;
			}
		}
	}*/
}
