.lo-ra-value-config-dialog {
	table {
		border-top: 1px solid @darkgrey;
		th {
			vertical-align: middle !important;
			.first-letter-uppercase-mixin();
			font-weight: bold;
			color: @darkgrey;
		}
		input {
			width: 100%;
		}
		select {
			width: 100%;
		}
		td button {
			margin-left: 2px;
		}
		tr.highlighted {
			background-color: yellow;
		}
		tr {
			transition: background-color 1s;
		}
	}
	
	tr.sortable-item-row {
		input[type="text"] {
			height: 26px;
			vertical-align: baseline;
		}
		select {
			vertical-align: baseline;
			height: 27px;
		}
	}
}

.csv-import-dialog {
	width: 600px;
	.dialog-body {
		/* Heading already has margin top, too much spacing */
		padding-top: 0px;
	}
}

.csv-import-form {
	.csv-import-explanation {
		margin-bottom: 10px;
		
		p {
			margin-top: 5px;
			line-height: 1.25;
		}
		strong {
			display:inline-block;
			font-weight:bold;
			color: @darkgrey;
			padding: 15px 0 0 0;
		}
		
		table {
			th {
				background-color: @grey;
				padding: 2px;
				border: 1px @darkgrey solid;
			}
			td {
				padding: 2px;
				border: 1px @darkgrey solid;
			}
		}
	}
	
	.csv-import-example {
		margin-top: 10px;
	}
	
	.form-row {
		margin-top: 10px;
	}
}

.deactivated-datasource-tooltipp {
	margin-top: -8px;
}


