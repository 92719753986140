.manage-messages-page {
	color: @darkgrey;
	
	.left-column {
		float: left;
		width: 40%;
		padding-right: 20px;
	}
	
	.right-column {
		float: left;
		width: 60%;
		padding-left: 20px;
	}

	.message-table {
		@colWidthSum: 6;
		
		.date-col {
			width: 2 * (100% / @colWidthSum);
		}
		.subject-col {
			width: 3 * (100% / @colWidthSum);
		}
		.attachment-col {
			width: 1 * (100% / @colWidthSum);
		}
		
		tr.new-message {
			font-weight: bold;
		}
	}
	
	.message {
		margin-top: 50px;
		
		.message-subject {
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: bold;
		}
		
		.message-content {
			
		}
		
		.message-date {
			margin-top: 20px;
			font-weight: bold;
		}
	}
}

.read-message-dialog {
	width: 600px;
	
	.dialog-body {
		color: @darkgrey;
		
		.message-subject {
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: bold;
		}
		
		.message-content {
			
		}
		
		.message-date {
			margin-top: 20px;
			font-weight: bold;
		}
	}
}

.write-message-dialog {
	width: 600px;

	.tree-container {
		display: inline-block;
		width: 500px - 140px;
	}

	.form-row {
		.kru-form-field {
			width: 510px - 140px;
		}
	}
}

