// see also general/service/StyleService.ts

@media only screen  
and (max-device-width : 736px) {
	
	body.mobile-available{
		div#layout-container.login-layout-container {
			width:100vw;
			height:100vh;
			overflow:hidden;
			min-width:0;
		}
		
		#layout-footer.layout-login-footer {
			min-width:0;
		}
	
		div#layout-header {
			div.session-box.mobile-visible {
				display:block;
				position:fixed;
				width: @menu-width-mobile;
				height: @menu-height-mobile;
				top: @menu-height-mobile;
				right:auto;
				z-index: 99999;
				
				.unread-messages-counter {
					position:fixed;
					top: @menu-height-mobile;
					left: ~"calc("@menu-width-mobile ~" - 20px)";
					height:20px;
					width:20px;
					font-size: 10px;
				}
				
				#login-name {
					display:none;
				}
				
				a {
					margin:5px;
					svg {
						width: 35px;
						height: 30px;
						margin: 0;
					}
				}
				a:nth-of-type(1) {
					float:left;
				}
				
				a:nth-of-type(2) {
					float:right;
				}
				
				a:nth-of-type(3) {
					display:none;
				}
			}
		}
	
		div#layout-menu {
		
			div.container div.menu:not(mobile-visible) {
				div.label {
					width:200px;
				}
				div.item-container {
					.item {
						display:none;
					}
				}
			}
		
			div.container div.menu.mobile-visible {
				position:fixed;
				width: @menu-width-mobile;
				background-color: #E6E6E6;
				
				div.label {
					visibility:hidden;
				}
			
				div.item-container {
					position: fixed;
					-webkit-backface-visibility:hidden; 
					height: ~"calc(100vh - "@menu-height-mobile + @menu-height-mobile~")";
					width: @menu-width-mobile;
					top:80px;
					left:0;
					
					&+object {
						display:none !important;
					}
					
					.item {
					
						&.active {
							background-color: @color_main;
						}
					
						&:not(.active):nth-of-type(1) {
							background-color: #9A9A9A;
						}
						
						&:not(.active):nth-of-type(2) {
							background-color: #787878;
						}
						&.active ~ .item:not(.active):nth-of-type(2) {
							background-color: #9A9A9A;
						}
							
						&:not(.active):nth-of-type(3) {
							background-color: #787878;
						}
					
						height:~"calc((100vh - "@menu-height-mobile + @menu-height-mobile~")/3)";
						width:100%;
						display:block;
						position:relative;
						
						&+span{
							&.displayNone {
								display:block;
								z-index:100;
							}
							width:@menu-width-mobile;
							position:fixed;
							color:white;
							transform: translatey(-5vh);
							text-align:center;
						}
						
						svg {
							width:20vh;
							height:20vh;
							padding-bottom:5vh;
							.bothCentered();
						}
					}
				}
			}
		}
	
	
	}
	/* hier darf der blümel anfangen zu entwickeln */
	body.mobile-available {
		div.kru-context-menu-container {
			display:none;
			width: 100%;
		}
		div.manage-messages-page {
			.left-column, .right-colum {
				float: none;
				width: 100%;
			}
		}
		div#layout-container {
			padding-top: @menu-height-mobile;
		}

		div#layout-container div#layout-menu {
			height: @menu-height-mobile;
			min-width: 0;
			div.container {
				div.menu > object, div.menu > svg {
					height:@menu-height-mobile - 10px;
					margin:5px;
					display:inline-block;
				}
				width: 100%;
				
				div.label {
					font-size: 18px;
					padding-top: 10px;
					height: @menu-height-mobile;
					width: ~"calc(100vw - " 2*@menu-height-mobile ~")";
					padding-left: 10px;
				}
	
				div.dateSelector {
					width: @menu-height-mobile;
					div.dateSelectorBar.bar-collapsed {
						height: 5px;
						width: @menu-height-mobile;
						left: 0;
					}
					div.clock {
						height: @menu-height-mobile;
						padding: (@menu-height-mobile - 32px);
						span {
							display: none;
						}
					}
					div.dateSelectorBar {
						text-align: center;
						a {
							display: inline-block !important;
							width: 30px;
						}
						&.bar-collapsed a {
							display: none !important;
						}
						width: 110px;
						height: 50px;
						position: relative;
						left: -70px;
						#display-mode {
							line-height: 20px;
							vertical-align: middle;
							font-size: 12px;
							padding: 0;
							width: 110px;
						}
						a.date-bar-item {
							
						}
						a.date-selector-item svg {
							width: 20px;
							height: 20px;
							margin: 5px;
						}
					}
				}
			}
		}
		
		#layout-header {
			width: 100%;
			height: @menu-height-mobile;
			text-align: center;
			position: fixed;
			top: 0;
			background-color: white;
			z-index: 60000;
			.logo {
				position: static;
				top: 0;
				width: 300px;
				margin: 0 auto;
				padding: 0;
				height: @menu-height-mobile;
				background-size: auto @menu-height-mobile;
				background-repeat: no-repeat;
				background-position: center;
			}
			.session-box {
				display: none;
			}
		}
		
		#layout-content {
			width: 100%;
		}
		
		.tenant-content-widget {
			.big-column {
				width: 100% !important;
			}
			.small-column {
				display:none;
				.mobile-visible {
					display: block;
					&.close-tenant-additional-info-table-button {
						position: absolute;
						top: 0;
						right: 0;
						width: @content-widget-head-bar-height;
						height: @content-widget-head-bar-height;
						line-height: @content-widget-head-bar-height;
						text-align: center;
						background-color: @grey3;
						cursor: pointer;
					}
				}
				&.visible {
					display: block;
					>div {
						background-color: @grey4;
						position:fixed;
						width: 80vw !important;
						top: 20vh;
						height: 60vh !important;
						left: 10vw;
						z-index: 70010;
						padding: 20px;
					}
				}
				&::before {
					content:"";
					display:block;
					position:fixed;
					width: 100vw !important;
					top: 0;
					height: 100vh !important;
					left: 0;
					background-color: @grey5;
					opacity: .2;
					z-index: 70000;
				}
			}
			.head-bar {
				.head-bar-button {
					display: none;
				}
				.head-bar-button.mobile, div.head-bar-button.quantity-type-button {
					display: inline;
				}
			}
			.graph {
				.line-chart {
					width: 100% !important;
				}
			}
		}
		
		#layout-footer {
			min-width: 0px;
		}
		
		.tenant-content-widget .head-bar .head-bar-button.mobile.tenant-additional-info-table-button {
			background-color: @grey3;
			padding: @content-widget-head-bar-height/4;
			margin: 0;
			display: inline;
			position: relative;
			height: @content-widget-head-bar-height;
			width: @content-widget-head-bar-height;
			&>span {
				display:inline-block;
				vertical-align: middle;
				width: @content-widget-head-bar-height/2;
				height: @content-widget-head-bar-height/2;
				font-size: @headline-font-size/2;
				border: 1px solid white;
				border-radius: @content-widget-head-bar-height/4;
				
				span {
					position: relative;
					right: 0.1ex;
					bottom: 0.1ex;
					font-family: "Times New Roman", Serif;
					font-size: @content-widget-head-bar-height/2;
					vertical-align: middle;
					display: inline-block;
					padding: 0;
					line-height: @content-widget-head-bar-height/2;
					color: white;
					border-color: white;
				}
			}
		}
		#mobile-menu {
			cursor: pointer;
			display: block;
			height: @menu-height-mobile - 10px;
			width: @menu-height-mobile - 10px;
			margin: 5px;
			position: fixed;
			top: 0;
			left: 0;
			&.hidden {
				.cheeseburger {
					display:block;
				}
				.back {
					display:none;
				}
			}
			.cheeseburger {
				display:none;
			}
		}
	}
	&.login div#layout-header div#mobile-menu {
		.cheeseburger, .back {
			display: none;
		}
	}
}

.tenant-content-widget .head-bar .head-bar-button.mobile {
	display:none;
}

div#layout-container div#layout-menu div.container div.menu  {
	> object, > svg {
		display: none;
	}
}

#mobile-menu, .small-column .mobile-visible {
	display:none;
}

@media only screen  
and (max-device-width : 736px) {
	/* hier muss der blümel aufhören */
}

@media only screen
	and (max-device-width : 736px)
	and (orientation: landscape) {
	
	body.mobile-available div#layout-container.login-layout-container {
		overflow-y:scroll !important;
	}
	
}
