@filterBarHeight: 25px;
@filterBarItemBorderWidth: 1px;

.filter-bar {
	
}

.filter-bar:not(.vertical-filter-bar) {
	height: @filterBarHeight;
	margin-bottom: 10px;
	
	> .kru-button {
		display: inline-block;
		position: relative;
		height: @filterBarHeight;
		top: -1px;
		.first-letter-uppercase-mixin();
	}
	
	.filter-bar-item-container {
		display: inline-block;
		
		.filter-bar-item {
			display: inline-block;
			margin-left: 10px;
		}
	}
}

.filter-bar.vertical-filter-bar {
	margin-bottom: 10px;
	
	> .kru-button {
		display: inline-block;
		position: relative;
		height: @filterBarHeight;
		top: -1px;
		.first-letter-uppercase-mixin();
	}
	
	.filter-bar-item-container {
		display: block;
		
		.filter-bar-item {
			margin-top: 3px;
			
			.filter-bar-item-text {
				line-height: @filterBarHeight;
			}
					
			> .kru-button {
				float: right;
			}
		}
	}
}

.filter-bar-item {
	height: @filterBarHeight;
	padding: 0 0 0 10px;
	border: @filterBarItemBorderWidth solid grey;
	cursor: pointer;
	
	.filter-bar-item-text {
		display: inline-block;
		min-width: 175px;
	}
	
	> .kru-button {
		display: inline-block;
		width: @filterBarHeight - 2 * @filterBarItemBorderWidth;
		height: @filterBarHeight - 2 * @filterBarItemBorderWidth;
		margin-left: 15px;
	}
}
