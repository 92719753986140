
.license-overview-container {

	.install-id {
		display: inline-block;
		padding: 10px;
		background: @grey7;
		min-width: 250px;
		text-align: center;
	}

	.content-block {
		&.valid {
			.block-header {
				background: @color_main;
			}
		}

		&.expired {
			.block-header {
				background: darkorange;
			}
		}

		&.invalid {
			.block-header {
				background: @red;
			}
		}
	}
}
