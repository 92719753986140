
.info-block-container
{
	.content-block {
		display: inline-block;
		width : 400px;
		margin-right: 10px;
		margin-bottom: 10px;
		vertical-align: top;
		background: @grey7;

		.block-header {
			width: 100%;
			background: @color_main;
			color: white;
			font-size: 1.5em;
			padding: 5px;
		}

		.block-headline {
			width: 100%;
			padding: 5px;
			font-weight: bold;
		}

		&.selectable {
			cursor: pointer;
		}

		&.selected {
			background: @grey2;
		}

		dl {
			padding: 10px;
			margin-bottom: 5px;
			clear: both;
		}

		dt {
			width: 40%;
			vertical-align: top;
			text-align: right;
			margin-right: 6px;
			display:inline-block;
			line-height: 1.5em;
		}

		dd {
			line-height: 1.5em;
			display: inline-block;
			width: 55%;
			vertical-align: top;
		}

		dl.long-label dt {
			width: 65%;
		}

		dl.long-label dd {
			width: 30%;
		}
	}
}
