/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position: absolute;
	left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
	position: relative;
	top: 3px;
	padding-left: 25px;
	cursor: pointer;
	font-size: 17px;
	color: @color_content_head;
	user-select: none;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
	content: '';
	position: absolute;
	left:0; top: 2px;
	width: 17px; height: 17px;
	border: 2px solid @color_content_head;
	background: @white;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
	content: '✔';
	position: absolute;
	top: 5px; left: 4px;
	font-size: 13px;
	line-height: 0.8;
	color: @color_content_head;
	transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
	opacity: 0;
	transform: scale(0);
}
[type="checkbox"]:checked + label:after {
	opacity: 1;
	transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
	box-shadow: none;
	border-color: @grey4 !important;
	background-color: @grey;
	cursor: default;
}
[type="checkbox"]:disabled:checked + label:after {
	color: @grey4;
}
[type="checkbox"]:disabled + label {
	color: @grey4;
	cursor: default;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
	box-shadow: inset 0 1px 3px rgba(0,0,0,.2);
	background-color: @color_main_focus;
}

/* hover style just for information */
label:hover:before {
	border: 2px solid @color_content_head_hover !important;
}
