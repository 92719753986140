#system-update-table {

	th {
		width: 50%;
	}

	tr.running {
		color: steelblue;
	}
}
