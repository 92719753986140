
.animated {
	animation: 1s ease 0s 1 normal;
	-webkit-animation: 1s ease 0s 1 normal;
	animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

.animated.reversed {
	animation: 0.5s ease 0s 1 reverse both;
	-webkit-animation: 0.5s ease 0s 1 reverse both;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}


@-webkit-keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		-ms-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}

	50% {
		opacity: 1;
	}
}

.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}

	100% {
		opacity: 0;
	}
}

@keyframes zoomOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
		-webkit-transform: scale3d(.3, .3, .3);
		-ms-transform: scale3d(.3, .3, .3);
		transform: scale3d(.3, .3, .3);
	}

	100% {
		opacity: 0;
	}
}

.zoomOut {
	-webkit-animation-name: zoomOut;
	animation-name: zoomOut;
}
