.zeroOpacity {
  opacity: 0;
}
.fullOpacity {
  opacity: 1;
}
.displayNone {
  display: none;
}
*.hidden {
  display: none !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  background-color: white;
  font-size: 12px;
  font-family: sans-serif;
}
a {
  text-decoration: none;
  outline: none;
}
button {
  text-decoration: none;
  outline: none;
  border: none;
}
button::-moz-focus-inner {
  border: none;
}
/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */
/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */
/* static colors */
/* these variables are necessary for some autogeneration. see also /images/icons/svg.less, colors.less and create_svg.php */
/* all colors, svgs should be generated */
/* all colors, the generator shall make a hover-version of the svg */
/* only for colors.less */
/* example to add definitions from theme-NAME.less */
/* @additional_color_names: 'even', 'odd';
 */
/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */
/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */
/* The arrays are defined in static-colors.less */
#colors.color_histogram_bar_highlighted {
  color: #575756;
}
#colors.color_histogram_bar {
  color: #BDBCBC;
}
#colors.color_chart_axis {
  color: #575756;
}
#colors.color_line_chart_compare_sequence {
  color: green;
}
#colors.color_line_chart_main_sequence {
  color: #123456;
}
#colors.color_content_head {
  color: #575756;
}
#colors.color_white {
  color: #FFFFFF;
}
#colors.color_main {
  color: #6BC04B;
}
#colors.color_content_head_hover {
  color: #8a8a89;
}
#colors.color_white_hover {
  color: #FEFEFE;
}
#colors.color_main_hover {
  color: #86cb6b;
}
/* #colors {
    .-(@i: length(@additional_color_names)) when (@i > 0) {
        @name: e(extract(@additional_color_names, @i));
        @fullname: "@{name}";
        &.@{name} {color: @@fullname}
        .-((@i - 1));
    } .-;
}
 */
/* arrows */
.arr,
div.kru-context-menu-selector:after,
div.kru-context-menu-container.folded .kru-context-menu-selector:after,
.table thead th.sorted-asc:after,
.table thead th.sorted-desc:after {
  border: solid #6BC04B;
  border-width: 0 .2em .2em 0;
  display: inline-block;
  padding: .20em;
}
.arr-right,
div.kru-context-menu-selector:after {
  transform: rotate(-45deg);
}
.arr-left,
div.kru-context-menu-container.folded .kru-context-menu-selector:after {
  transform: rotate(135deg);
}
.arr-up,
.table thead th.sorted-asc:after {
  transform: rotate(-135deg);
}
.arr-down,
.table thead th.sorted-desc:after {
  transform: rotate(45deg);
}
html,
body {
  margin: 0;
  padding: 0;
}
#layout-container {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
body > #layout-container {
  height: auto;
  overflow: hidden;
}
*:focus {
  outline: none;
}
body {
  color: #575756;
}
#layout-header {
  position: relative;
  height: 120px;
  margin: 0px auto;
  width: 1260px;
}
#layout-header .logo {
  position: absolute;
  left: 0px;
  top: 20px;
  background: url('/images/theme/logo.svg');
  width: 400px;
  height: 80px;
}
#layout-header .session-info {
  position: absolute;
  top: 20px;
  height: 80px;
  right: 0px;
}
#layout-header .session-logged-in {
  position: absolute;
  top: 38px;
}
#layout-header span#login-name {
  position: relative;
  bottom: 18px;
  margin-right: 35px;
  color: #575756;
  font-weight: 500;
}
#layout-header div.unread-messages-counter {
  position: absolute;
  width: 26px;
  height: 26px;
  background: #6BC04B;
  border-radius: 50px;
  bottom: 60px;
  right: 100px;
  text-align: center;
  padding-top: 5px;
  color: white;
  font-weight: 500;
  font-size: 16px;
}
#layout-header div.unread-messages-counter.position-1 {
  right: 25px;
}
#layout-header div.unread-messages-counter.position-2 {
  right: 107px;
}
#layout-header div.unread-messages-counter.position-3 {
  right: 190px;
}
#layout-header .session-box a:first-child > svg {
  margin-right: 15px;
}
#layout-header .session-box-icons {
  width: 45px;
  height: 45px;
  color: #575756;
  margin-right: 35px;
}
#layout-header .session-box-icons:hover {
  color: #8a8a89;
}
#layout-menu {
  background: #6BC04B;
  height: 100px;
  min-width: 1260px;
  margin-bottom: 10px;
}
#layout-menu.has-horizontal-bar {
  margin-bottom: 2px;
}
#layout-menu .container {
  margin: 0px auto;
  width: 1260px;
  position: relative;
  z-index: 49998;
}
#layout-menu .container .menu {
  display: inline-block;
}
#layout-menu .container .menu.collapsable .item-container {
  transition: opacity 500ms;
}
#layout-menu .container .menu.collapsable .item-row {
  height: 100px;
}
#layout-menu .container .item {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #6BC04B;
  transition: background 0.1s linear 0s;
}
#layout-menu .container .item svg {
  width: 60px;
  height: 60px;
  margin: 20px;
}
#layout-menu .container .item:hover {
  background: #54a038;
}
#layout-menu .container .item-container {
  height: 100px;
}
#layout-menu .container.menu-horizontal-display .item-container {
  display: inline;
}
#layout-menu .container .item.active {
  background: #86cb6b;
}
#layout-menu .container div.label {
  color: #FFFFFF;
  font-size: 34px;
  padding-top: 32px;
  padding-left: 32px;
  display: inline-block;
  vertical-align: top;
  height: 100px;
}
#layout-menu .container div.label::first-letter {
  text-transform: uppercase;
}
#layout-menu .container div.dateSelector {
  background-color: #575756;
  width: 350px;
  display: inline-block;
  float: right;
}
#layout-menu .container div.dateSelector div.clock {
  background-color: #6BC04B;
  padding-bottom: 7px;
  padding-left: 20px;
  height: 100px;
  padding-top: 68px;
}
#layout-menu .container div.dateSelector div.clock span {
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
  padding-left: 8px;
  padding-top: 6px;
  color: #575756;
  font-size: 14px;
  user-select: none;
}
#layout-menu .container div.dateSelector div.clock svg {
  width: 25px;
  height: 25px;
  color: #575756;
}
#layout-menu .container div.dateSelector div.dateSelectorBar {
  background-color: #575756;
  height: 52px;
  transition: height 100ms linear;
}
#layout-menu .container div.dateSelector div.dateSelectorBar a.date-selector-item svg {
  width: 30px;
  height: 30px;
  margin: 10px;
}
#layout-menu .container div.dateSelector div.dateSelectorBar a.date-selector-item:hover svg {
  color: #6BC04B;
}
#layout-menu .container div.dateSelector div.dateSelectorBar a.date-bar-item-selected {
  cursor: default;
}
#layout-menu .container div.dateSelector div.dateSelectorBar a.date-bar-item-selected svg {
  color: #6BC04B;
}
#layout-menu .container div.dateSelector div.bar-collapsed {
  height: 11px;
}
#layout-menu .container div.dateSelector span#display-mode {
  color: #6BC04B;
  width: 140px;
  vertical-align: top;
  display: inline-block;
  padding: 20px;
  font-size: 14px;
  user-select: none;
  cursor: default;
}
div#layout-menu-horizontal-bar {
  background: linear-gradient(to right, white 50%, #6BC04B 50%);
}
div.horizontal-bar {
  margin: 0px auto;
  width: 1260px;
  background-color: #6BC04B;
  position: relative;
  left: 100px;
  height: 40px;
  margin-bottom: 10px;
}
div.horizontal-bar ul li {
  display: inline;
}
div.horizontal-bar ul li.active a {
  background-color: #86cb6b;
}
div.horizontal-bar ul a {
  font-size: 18px;
  color: white;
  background-color: #6BC04B;
  height: 40px;
  padding: 10px;
  display: inline-block;
}
div.horizontal-bar ul a:hover {
  background-color: #54a038;
}
#layout-footer {
  background: #6BC04B;
  clear: both;
  position: relative;
  z-index: 10;
  height: 40px;
  margin-top: -40px;
  vertical-align: top;
  min-width: 532px;
}
#layout-footer .content {
  text-align: center;
  padding-top: 7px;
}
#layout-footer .content .flag {
  display: inline-block;
  width: 24px;
  height: 26px;
  background: url('/images/schweizer_wappen.svg');
  background-size: 24px 26px;
}
#layout-footer .content .text {
  display: inline-block;
  height: 26px;
  color: #FFFFFF;
  vertical-align: top;
  font-size: 15px;
  padding-top: 4px;
}
#layout-content {
  margin: 0px auto;
  width: 1260px;
  padding-bottom: calc(50px);
}
#layout-content > div:first-child {
  margin-top: 56px;
}
#layout-content > div:first-child.breadcrumb {
  margin-top: auto;
}
.treelayout .tree-container {
  float: left;
  position: relative;
  width: 30%;
}
.treelayout .jstree {
  height: 65vh;
  overflow-y: auto;
  min-height: 80px;
}
.treelayout .content-container {
  float: left;
  position: relative;
  width: 70%;
  padding-left: 10px;
  color: #575756;
  min-height: 80px;
}
.treelayout:after {
  content: "\00A0";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.layout-content-width {
  margin: 0px auto;
  width: 1260px;
}
/* svg-links */
object[data$=".svg"] {
  pointer-events: none;
}
.clearfix:after {
  content: "\00A0";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.clearfix {
  display: inline-block;
}
html[xmlns] .clearfix {
  display: block;
}
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
.center {
  text-align: center;
  margin: auto;
}
.vertCentered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.horizCentered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.bothCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading-spinner {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /*border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.05);*/
}
.loading-spinner .loading-spinner-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  background-image: url("/images/gif/spinner.png.gif");
  background-size: 100%, 100%;
}
.loading-spinner .loading-spinner-text {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  color: white;
  font-size: 30px;
  transform: translate(-50%, -50%);
}
.capitalize,
.capitalize * {
  text-transform: capitalize;
}
.first-letter-uppercase:first-letter,
.first-letter-uppercase *:first-letter {
  text-transform: uppercase;
}
.first-letter-uppercase-mixin:first-letter {
  text-transform: uppercase;
}
.relative {
  position: relative;
}
.return-to-overview-link {
  color: #575756;
}
.return-to-overview-link:first-letter {
  text-transform: uppercase;
}
.template {
  display: none;
}
.error {
  color: red;
}
.warning {
  color: orange;
}
.success {
  color: green;
}
div.kru-context-menu-container {
  position: fixed;
  transition: right 1s, top 1s;
  right: 0;
  top: 345px;
  z-index: 11;
  /* bigger than footer z-index 10 */
}
div.kru-context-menu-selector {
  position: absolute;
  top: 50px;
  width: 0px;
  right: 200px;
  min-height: 200px;
  border-right: 28px solid #575756;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  cursor: pointer;
  transition: border 500ms ease-out;
}
div.kru-context-menu-selector:after {
  content: "";
  position: relative;
  width: 15px;
  height: 15px;
  top: 85px;
  left: 4px;
  border-color: white !important;
  border-width: 0 0.2em 0.2em 0;
}
div.kru-context-menu {
  position: absolute;
  width: 200px;
  min-height: 300px;
  right: 0;
  background-color: #575756;
  transition: right 1s;
}
div.kru-context-menu ol li {
  cursor: pointer;
  background-color: #575756;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 14px;
  transition: background 0.5s;
  margin: 0;
}
div.kru-context-menu ol li:first-letter {
  text-transform: uppercase;
}
div.kru-context-menu ol li:hover {
  background-color: #71716f;
}
div.kru-context-menu ol li.disabled {
  cursor: default;
  color: #71716f;
}
div.kru-context-menu ol li.disabled:hover {
  background-color: #575756;
}
div.kru-context-menu-container.folded {
  right: -200px;
}
div.kru-context-menu-container.folded .kru-context-menu-selector:after {
  left: 12px;
}
@media screen and (min-width: 1660px) {
  div.kru-context-menu-container.folded {
    right: 0px;
  }
  div.kru-context-menu-selector {
    border-right: 8px solid #575756;
    cursor: default;
    pointer-events: none;
  }
  div.kru-context-menu-selector:after {
    border-color: transparent !important;
  }
}
@media screen and (max-height: 1080px) {
  div.kru-context-menu-container {
    top: 274px;
  }
}
@media screen and (max-height: 850px) {
  div.kru-context-menu-container {
    top: 240px;
  }
}
@media screen and (max-height: 700px) {
  div.kru-context-menu-container {
    top: 200px;
  }
}
@media screen and (max-height: 600px) {
  div.kru-context-menu-container {
    top: 160px;
  }
}
ul.breadcrumb {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
ul.breadcrumb li {
  display: inline-block;
  height: 26px;
  padding: 0 0;
  font-size: 13px;
}
ul.breadcrumb li + li {
  margin-left: -8px;
}
ul.breadcrumb li + li::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-top: 13px solid #575756;
  border-bottom: 13px solid #575756;
  vertical-align: middle;
}
ul.breadcrumb li::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid #575756;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  vertical-align: middle;
}
ul.breadcrumb li span {
  background: #575756;
  color: #FFFFFF;
  padding: 5px 10px;
  line-height: 16px;
  vertical-align: middle;
  display: inline-block;
}
ul.breadcrumb li:first-child span {
  padding-left: 15px;
}
.breadcrumb + div.tooltipp {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  font-size: 13px;
  border: 1px solid #575756;
  border-radius: 13px;
}
.breadcrumb + div.tooltipp span {
  position: relative;
  right: 0.1ex;
  bottom: 0.1ex;
  color: #575756;
  font-family: "Times New Roman", Serif;
  font-size: 26px;
  vertical-align: middle;
  display: inline-block;
  padding: 0 10px;
  line-height: 26px;
}
.breadcrumb + div.tooltipp div.container {
  z-index: -1000;
  top: 34px;
  left: -165px;
  width: 350px;
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.5s linear, z-index 0s linear 0.5s;
  display: inline;
  position: absolute;
  background: #575756;
  border: 1px solid #bdbdbc;
  color: #FFFFFF;
}
.breadcrumb + div.tooltipp div.container div.arrow-up {
  z-index: 20;
  position: absolute;
  border: 0;
  top: -10px;
  left: 165px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #575756;
}
.breadcrumb + div.tooltipp div.container div.title {
  padding: 6px 10px;
  background: #8a8a89;
  font-weight: bold;
}
.breadcrumb + div.tooltipp div.container div.content {
  padding: 5px 10px;
}
.breadcrumb + div.tooltipp div.container div.content label {
  width: 35%;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}
.breadcrumb + div.tooltipp:hover div.container {
  opacity: 1;
  z-index: 10;
  transition: opacity 0.5s linear;
}
.table {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.table thead th {
  text-align: left;
  padding: 5px;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  outline-style: none;
  /* IE */
}
.table thead th:first-letter {
  text-transform: uppercase;
}
.table thead th[data-sort-criteria] {
  cursor: pointer;
}
.table thead th.sorted {
  font-style: italic;
}
.table thead th.sorted-asc:after,
.table thead th.sorted-desc:after {
  content: "";
  float: right;
  border-color: white;
  border-width: 0 .1em .1em 0;
  margin-right: 8px;
}
.table thead th.sorted-asc:after {
  margin-top: 7px;
}
.table thead th.sorted-desc:after {
  margin-top: 3px;
}
.table tbody {
  position: relative;
}
.table tbody tr {
  vertical-align: middle;
}
.table tbody tr:not(:last-child) {
  border-bottom: none;
}
.table tbody tr:hover {
  color: inherit;
}
.table tbody tr.selected {
  background-color: #6BC04B !important;
  color: #FFFFFF;
}
.table tbody tr.selected {
  font-style: italic;
}
.table tbody tr td {
  vertical-align: middle;
}
.table tbody tr.empty-table-message-row {
  pointer-events: none;
}
.table tbody tr.empty-table-message-row td:first-letter {
  text-transform: uppercase;
}
.table tbody tr[data-id] td {
  padding: 5px;
  padding-left: 10px;
  cursor: pointer;
}
.table.reloading tbody {
  pointer-events: none;
}
.table td.number-cell {
  text-align: right;
}
.table td.empty-cell {
  opacity: 0.6;
}
.table.table1 thead:after {
  content: "-";
  display: block;
  line-height: 2em;
  color: transparent;
}
.table.table1 thead th {
  font-size: 16px;
  color: #575756;
}
.table.table1 thead th.sorted {
  background-color: #6BC04B;
  color: #FFFFFF;
}
.table.table1 tbody tr {
  height: 25px;
  color: #575756;
}
.table.table1 tbody tr:hover {
  color: inherit;
}
.table.table1 tbody tr.selected {
  background-color: #575756 !important;
  color: #FFFFFF;
}
.table.table1 tbody tr:nth-child(odd):not(.empty-table-message-row) {
  background-color: #ececec;
}
table.table2 thead {
  background-color: #575756;
}
table.table2 thead tr th {
  font-size: 14px;
  color: white;
}
table.table2 tbody tr td {
  padding-left: 10px;
}
.table2.rawvalues tbody tr td {
  font-family: "Inconsolata", "Fira Mono", "Source Code Pro", Monaco, Consolas, "Lucida Console", monospace;
}
.datetime-table-col-width {
  width: 150px;
}
.number-cell {
  text-align: right;
}
dl.info-list {
  width: 400px;
  color: #575756;
  font-size: 14px;
}
dl.info-list:after {
  content: "\00A0";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
dl.info-list dt,
dl.info-list dd {
  display: block;
  float: left;
  width: 50%;
  margin-bottom: 10px;
}
dl.info-list dt {
  clear: both;
}
dl.info-list dt:first-letter {
  text-transform: uppercase;
}
/**
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */
.dialog-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 59998;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}
.dialog {
  position: relative;
  width: 430px;
  margin: 10vh auto 0;
  background: #FFFFFF;
  font-size: 14px;
}
.dialog .jstree {
  overflow: auto;
  height: 20vh;
}
.dialog .dialog-head {
  height: 50px;
  line-height: 50px;
  background-color: #71716f;
  text-align: center;
  vertical-align: middle;
}
.dialog .dialog-head .dialog-title {
  display: block /* so first-letter-uppercase works */;
  color: white;
  font-size: 18px;
}
.dialog .dialog-head .dialog-title:first-letter {
  text-transform: uppercase;
}
.dialog .dialog-body {
  padding: 30px 45px;
}
.dialog .dialog-body .dialog-button-panel {
  margin-top: 40px;
  text-align: center;
}
.dialog .dialog-body .dialog-button-panel:after {
  content: '';
  display: block;
  clear: both;
}
.dialog .dialog-body .dialog-button-panel .kru-button {
  min-width: 120px;
  font-size: 14px;
}
.dialog .dialog-body .dialog-button-panel .cancel-button {
  background-color: #71716f;
}
button,
input[type="submit"],
.kru-button {
  background-color: #6BC04B;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: background-color 0.5s;
  /* For Safari 3.1 to 6.0 */
  transition: background-color 0.5s;
  text-decoration: none;
  padding: 5px 13px;
  color: #FFFFFF;
  font-size: 12px;
  font-family: sans-serif;
  border: none;
  outline: none;
  text-align: center;
  -moz-user-select: none;
}
button:first-letter,
input[type="submit"]:first-letter,
.kru-button:first-letter {
  text-transform: uppercase;
}
button:active,
input[type="submit"]:active,
.kru-button:active {
  background-color: #a5d991;
  padding: 5px 13px;
}
button:hover,
input[type="submit"]:hover,
.kru-button:hover {
  background-color: #5eb33f;
}
button:disabled,
input[type="submit"]:disabled,
.kru-button:disabled {
  background-color: #8acd71;
  color: #54a038;
  cursor: default;
}
button:focus,
input[type="submit"]:focus,
.kru-button:focus {
  padding: 5px 13px;
}
button.big,
input[type="submit"].big,
.kru-button.big {
  height: 40px;
  font-size: 17px;
  line-height: 1.7;
}
button.block,
input[type="submit"].block,
.kru-button.block {
  display: block;
  width: 100%;
}
button.icon,
input[type="submit"].icon,
.kru-button.icon {
  padding-left: 25px;
}
button.icon:active,
input[type="submit"].icon:active,
.kru-button.icon:active,
button.icon:focus,
input[type="submit"].icon:focus,
.kru-button.icon:focus {
  padding-left: 25px;
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
  color: #575756;
}
h1 {
  margin-top: 30px;
  margin-bottom: 14px;
  font-size: 24px;
  text-transform: uppercase;
}
h2 {
  margin-top: 27px;
  margin-bottom: 12px;
  font-size: 20px;
  text-transform: uppercase;
}
h3 {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 16px;
  text-transform: uppercase;
}
h4 {
  margin-bottom: 8px;
  font-size: 14px;
}
h4:first-letter {
  text-transform: uppercase;
}
.tooltip-parent {
  position: relative;
}
.tooltip-parent:hover .tooltip {
  z-index: 10;
  opacity: 1;
  transition: opacity 0.5s linear;
}
.tooltip-parent .tooltip {
  display: inline;
  position: absolute;
  z-index: -1000;
  opacity: 0;
  transition: opacity 0.5s linear, z-index 0s linear 0.5s;
  padding: 5px;
  border: 1px solid #bdbdbc;
  background: #575756;
  text-align: left;
  line-height: 1.2;
  color: #FFFFFF;
}
.animated {
  animation: 1s ease 0s 1 normal;
  -webkit-animation: 1s ease 0s 1 normal;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated.reversed {
  animation: 0.5s ease 0s 1 reverse both;
  -webkit-animation: 0.5s ease 0s 1 reverse both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}
@media screen and (max-height: 1080px) {
  #layout-header {
    height: 90px;
  }
  #layout-header .logo {
    top: 5px;
  }
  #layout-header .session-logged-in {
    top: 25px;
  }
  #layout-header .session-box-icons {
    width: 40px;
    height: 40px;
  }
  #layout-header div.unread-messages-counter.position-1 {
    right: 20px;
  }
  #layout-header div.unread-messages-counter.position-2 {
    right: 97px;
  }
  #layout-header div.unread-messages-counter.position-3 {
    right: 175px;
  }
  #layout-menu {
    height: 60px;
  }
  #layout-menu .container a.item {
    width: 60px;
    height: 60px;
  }
  #layout-menu .container a.item svg {
    width: 40px;
    height: 40px;
    margin: 10px;
  }
  #layout-menu .container .menu.collapsable .item-row {
    height: 60px;
  }
  #layout-menu .container div.label {
    font-size: 24px;
    padding-top: 20px;
    height: 60px;
  }
  #layout-menu .container div.dateSelector div.bar-collapsed {
    height: 10px;
  }
  #layout-menu .container div.dateSelector div.clock {
    height: 60px;
    padding-top: 28px;
  }
  div.horizontal-bar {
    left: 60px;
  }
  /*.tenant-content-widget {
		@content-widget-height: 275px;
		@head-icon-size: 50px;
		@headbar-offset: -7px;
		@content-widget-header-bar-height: 30px;
		@headline-font-size: 20px;

		height: @content-widget-height;

		.head-icon {
			width: @head-icon-size;
			height: @head-icon-size
		}

		.head-bar {
			width: calc(~"100%" - @head-icon-size + @headbar-offset);
			height: @content-widget-header-bar-height;

			.headline{
				font-size: @headline-font-size;
			}
		}
	}*/
}
/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */
.datetime-popup {
  position: absolute;
  top: 30px;
  width: 250px;
  padding: 10px;
  z-index: 100;
  border: 1px solid #e4e4e4;
  background-color: #f6f6f6;
  color: #575756;
}
.datetime-popup .moment-picker-calendar {
  margin-bottom: 20px;
}
.datetime-popup .time-selection {
  width: 40%;
  margin-left: 30%;
  margin-bottom: 20px;
}
.datetime-popup .hour-selection,
.datetime-popup .minute-selection {
  display: block;
  float: left;
  width: 45%;
}
.datetime-popup .time-seperator {
  display: block;
  float: left;
  width: 10%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.datetime-popup .hour-input,
.datetime-popup .minute-input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: #e4e4e4;
  text-align: center;
  font-size: 16px;
  color: #575756;
}
.datetime-popup .time-button {
  width: 20px;
  height: 20px;
  margin: auto;
  cursor: pointer;
}
.datetime-popup.hidden {
  display: none;
}
.datetime-popup-buttons > * {
  float: right;
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.datetime-popup-buttons > .ok-button {
  color: #6BC04B;
}
.moment-picker {
  width: 100%;
}
.moment-picker table {
  width: 100%;
}
.moment-picker-year-select,
.moment-picker-month-select {
  width: 50%;
  font-size: 16px;
}
.moment-picker-calendar {
  margin-top: 20px;
}
.moment-picker-calendar th {
  font-weight: bold;
  padding-bottom: 4px;
}
.moment-picker-calendar td {
  width: 14.285%;
  height: 20px;
  text-align: center;
  vertical-align: middle;
}
.moment-picker-calendar-cell {
  cursor: pointer;
}
.moment-picker-calendar-cell-disabled {
  color: #bdbdbc;
  cursor: default;
}
.moment-picker-calendar-cell-other-month {
  color: #cdced1;
}
.moment-picker-calendar-cell-selected {
  background-color: #6BC04B;
  color: #575756;
}
.jstree-default i.jstree-icon.jstree-themeicon.jstree-themeicon-custom {
  width: 21px;
  height: 21px;
}
.filter-bar:not(.vertical-filter-bar) {
  height: 25px;
  margin-bottom: 10px;
}
.filter-bar:not(.vertical-filter-bar) > .kru-button {
  display: inline-block;
  position: relative;
  height: 25px;
  top: -1px;
}
.filter-bar:not(.vertical-filter-bar) > .kru-button:first-letter {
  text-transform: uppercase;
}
.filter-bar:not(.vertical-filter-bar) .filter-bar-item-container {
  display: inline-block;
}
.filter-bar:not(.vertical-filter-bar) .filter-bar-item-container .filter-bar-item {
  display: inline-block;
  margin-left: 10px;
}
.filter-bar.vertical-filter-bar {
  margin-bottom: 10px;
}
.filter-bar.vertical-filter-bar > .kru-button {
  display: inline-block;
  position: relative;
  height: 25px;
  top: -1px;
}
.filter-bar.vertical-filter-bar > .kru-button:first-letter {
  text-transform: uppercase;
}
.filter-bar.vertical-filter-bar .filter-bar-item-container {
  display: block;
}
.filter-bar.vertical-filter-bar .filter-bar-item-container .filter-bar-item {
  margin-top: 3px;
}
.filter-bar.vertical-filter-bar .filter-bar-item-container .filter-bar-item .filter-bar-item-text {
  line-height: 25px;
}
.filter-bar.vertical-filter-bar .filter-bar-item-container .filter-bar-item > .kru-button {
  float: right;
}
.filter-bar-item {
  height: 25px;
  padding: 0 0 0 10px;
  border: 1px solid grey;
  cursor: pointer;
}
.filter-bar-item .filter-bar-item-text {
  display: inline-block;
  min-width: 175px;
}
.filter-bar-item > .kru-button {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin-left: 15px;
}
.kru-objectpicker-selection-display {
  display: block;
  margin-top: 20px;
  text-align: right;
}
table.kru-objectpicker-table {
  width: 100%;
}
table.kru-objectpicker-table thead,
table.kru-objectpicker-table tbody,
table.kru-objectpicker-table tr,
table.kru-objectpicker-table td,
table.kru-objectpicker-table th {
  display: block;
}
table.kru-objectpicker-table tr {
  height: auto !important;
  min-height: 25px;
}
table.kru-objectpicker-table tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}
table.kru-objectpicker-table tbody {
  overflow-y: auto;
}
table.kru-objectpicker-table tbody td,
table.kru-objectpicker-table thead th {
  float: left;
}
table.kru-objectpicker-table tbody > tr:last-child {
  border-bottom-style: none;
}
.kru-objectpicker-list {
  min-height: 150px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.datapoint-picker-row {
  cursor: pointer;
}
.datapoint-picker-row td {
  font-size: 13px;
}
.datapoint-picker-row td > * {
  vertical-align: middle;
}
.datapoint-picker-row img.type-icon {
  margin-right: 5px;
}
@media only screen and (max-device-width: 736px) {
  body.mobile-available div#layout-container.login-layout-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    min-width: 0;
  }
  body.mobile-available #layout-footer.layout-login-footer {
    min-width: 0;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible {
    display: block;
    position: fixed;
    width: 60vmin;
    height: 40px;
    top: 40px;
    right: auto;
    z-index: 99999;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible .unread-messages-counter {
    position: fixed;
    top: 40px;
    left: calc( 60vmin  - 20px);
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible #login-name {
    display: none;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible a {
    margin: 5px;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible a svg {
    width: 35px;
    height: 30px;
    margin: 0;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible a:nth-of-type(1) {
    float: left;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible a:nth-of-type(2) {
    float: right;
  }
  body.mobile-available div#layout-header div.session-box.mobile-visible a:nth-of-type(3) {
    display: none;
  }
  body.mobile-available div#layout-menu div.container div.menu:not(mobile-visible) div.label {
    width: 200px;
  }
  body.mobile-available div#layout-menu div.container div.menu:not(mobile-visible) div.item-container .item {
    display: none;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible {
    position: fixed;
    width: 60vmin;
    background-color: #E6E6E6;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.label {
    visibility: hidden;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container {
    position: fixed;
    -webkit-backface-visibility: hidden;
    height: calc(100vh -  80px );
    width: 60vmin;
    top: 80px;
    left: 0;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container + object {
    display: none !important;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item {
    height: calc((100vh -  80px )/3);
    width: 100%;
    display: block;
    position: relative;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item.active {
    background-color: #6BC04B;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item:not(.active):nth-of-type(1) {
    background-color: #9A9A9A;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item:not(.active):nth-of-type(2) {
    background-color: #787878;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item.active ~ .item:not(.active):nth-of-type(2) {
    background-color: #9A9A9A;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item:not(.active):nth-of-type(3) {
    background-color: #787878;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item + span {
    width: 60vmin;
    position: fixed;
    color: white;
    transform: translatey(-5vh);
    text-align: center;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item + span.displayNone {
    display: block;
    z-index: 100;
  }
  body.mobile-available div#layout-menu div.container div.menu.mobile-visible div.item-container .item svg {
    width: 20vh;
    height: 20vh;
    padding-bottom: 5vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  /* hier darf der blümel anfangen zu entwickeln */
  body.mobile-available div.kru-context-menu-container {
    display: none;
    width: 100%;
  }
  body.mobile-available div.manage-messages-page .left-column,
  body.mobile-available div.manage-messages-page .right-colum {
    float: none;
    width: 100%;
  }
  body.mobile-available div#layout-container {
    padding-top: 40px;
  }
  body.mobile-available div#layout-container div#layout-menu {
    height: 40px;
    min-width: 0;
  }
  body.mobile-available div#layout-container div#layout-menu div.container {
    width: 100%;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.menu > object,
  body.mobile-available div#layout-container div#layout-menu div.container div.menu > svg {
    height: 30px;
    margin: 5px;
    display: inline-block;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.label {
    font-size: 18px;
    padding-top: 10px;
    height: 40px;
    width: calc(100vw -  80px );
    padding-left: 10px;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector {
    width: 40px;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.dateSelectorBar.bar-collapsed {
    height: 5px;
    width: 40px;
    left: 0;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.clock {
    height: 40px;
    padding: 8px;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.clock span {
    display: none;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.dateSelectorBar {
    text-align: center;
    width: 110px;
    height: 50px;
    position: relative;
    left: -70px;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.dateSelectorBar a {
    display: inline-block !important;
    width: 30px;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.dateSelectorBar.bar-collapsed a {
    display: none !important;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.dateSelectorBar #display-mode {
    line-height: 20px;
    vertical-align: middle;
    font-size: 12px;
    padding: 0;
    width: 110px;
  }
  body.mobile-available div#layout-container div#layout-menu div.container div.dateSelector div.dateSelectorBar a.date-selector-item svg {
    width: 20px;
    height: 20px;
    margin: 5px;
  }
  body.mobile-available #layout-header {
    width: 100%;
    height: 40px;
    text-align: center;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 60000;
  }
  body.mobile-available #layout-header .logo {
    position: static;
    top: 0;
    width: 300px;
    margin: 0 auto;
    padding: 0;
    height: 40px;
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-position: center;
  }
  body.mobile-available #layout-header .session-box {
    display: none;
  }
  body.mobile-available #layout-content {
    width: 100%;
  }
  body.mobile-available .tenant-content-widget .big-column {
    width: 100% !important;
  }
  body.mobile-available .tenant-content-widget .small-column {
    display: none;
  }
  body.mobile-available .tenant-content-widget .small-column .mobile-visible {
    display: block;
  }
  body.mobile-available .tenant-content-widget .small-column .mobile-visible.close-tenant-additional-info-table-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #8a8a89;
    cursor: pointer;
  }
  body.mobile-available .tenant-content-widget .small-column.visible {
    display: block;
  }
  body.mobile-available .tenant-content-widget .small-column.visible > div {
    background-color: #bdbdbc;
    position: fixed;
    width: 80vw !important;
    top: 20vh;
    height: 60vh !important;
    left: 10vw;
    z-index: 70010;
    padding: 20px;
  }
  body.mobile-available .tenant-content-widget .small-column::before {
    content: "";
    display: block;
    position: fixed;
    width: 100vw !important;
    top: 0;
    height: 100vh !important;
    left: 0;
    background-color: #71716f;
    opacity: .2;
    z-index: 70000;
  }
  body.mobile-available .tenant-content-widget .head-bar .head-bar-button {
    display: none;
  }
  body.mobile-available .tenant-content-widget .head-bar .head-bar-button.mobile,
  body.mobile-available .tenant-content-widget .head-bar div.head-bar-button.quantity-type-button {
    display: inline;
  }
  body.mobile-available .tenant-content-widget .graph .line-chart {
    width: 100% !important;
  }
  body.mobile-available #layout-footer {
    min-width: 0px;
  }
  body.mobile-available .tenant-content-widget .head-bar .head-bar-button.mobile.tenant-additional-info-table-button {
    background-color: #8a8a89;
    padding: 7.5px;
    margin: 0;
    display: inline;
    position: relative;
    height: 30px;
    width: 30px;
  }
  body.mobile-available .tenant-content-widget .head-bar .head-bar-button.mobile.tenant-additional-info-table-button > span {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    font-size: 12.5px;
    border: 1px solid white;
    border-radius: 7.5px;
  }
  body.mobile-available .tenant-content-widget .head-bar .head-bar-button.mobile.tenant-additional-info-table-button > span span {
    position: relative;
    right: 0.1ex;
    bottom: 0.1ex;
    font-family: "Times New Roman", Serif;
    font-size: 15px;
    vertical-align: middle;
    display: inline-block;
    padding: 0;
    line-height: 15px;
    color: white;
    border-color: white;
  }
  body.mobile-available #mobile-menu {
    cursor: pointer;
    display: block;
    height: 30px;
    width: 30px;
    margin: 5px;
    position: fixed;
    top: 0;
    left: 0;
  }
  body.mobile-available #mobile-menu.hidden .cheeseburger {
    display: block;
  }
  body.mobile-available #mobile-menu.hidden .back {
    display: none;
  }
  body.mobile-available #mobile-menu .cheeseburger {
    display: none;
  }
  .login div#layout-header div#mobile-menu .cheeseburger,
  .login div#layout-header div#mobile-menu .back {
    display: none;
  }
}
.tenant-content-widget .head-bar .head-bar-button.mobile {
  display: none;
}
div#layout-container div#layout-menu div.container div.menu > object,
div#layout-container div#layout-menu div.container div.menu > svg {
  display: none;
}
#mobile-menu,
.small-column .mobile-visible {
  display: none;
}
@media only screen and (max-device-width: 736px) {
  /* hier muss der blümel aufhören */
}
@media only screen and (max-device-width: 736px) and (orientation: landscape) {
  body.mobile-available div#layout-container.login-layout-container {
    overflow-y: scroll !important;
  }
}
.native-list ul,
.native-list ol {
  list-style: initial;
  margin-block-start: initial;
  margin-block-end: initial;
  padding: initial;
  padding-inline-start: 1em;
}
.markdown em {
  font-style: italic;
}
.markdown strong {
  font-weight: bold;
}
.markdown ul,
.markdown ol {
  margin-top: 8px;
  margin-bottom: 8px;
}
.markdown ol li {
  list-style: decimal;
  margin-left: 20px;
}
.markdown ul li {
  list-style: disc;
  margin-left: 20px;
}
.markdown code {
  font-family: 'Fira Mono', monospace;
}
.markdown p {
  margin-top: 1em;
  margin-bottom: 1em;
}
.kru-progress {
  background-color: #f6f6f6;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  height: 20px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;
}
.kru-progress .progress-bar {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  color: #000000;
  float: left;
  font-size: 12px;
  height: 100%;
  line-height: 20px;
  text-align: center;
  transition: width 0.6s ease 0s;
}
.kru-progress .progress-percent {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  line-height: 21px;
}
.kru-progress .progress-bar-fine {
  background-color: #6BC04B;
}
.info-block-container .content-block {
  display: inline-block;
  width: 400px;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: top;
  background: #ececec;
}
.info-block-container .content-block .block-header {
  width: 100%;
  background: #6BC04B;
  color: white;
  font-size: 1.5em;
  padding: 5px;
}
.info-block-container .content-block .block-headline {
  width: 100%;
  padding: 5px;
  font-weight: bold;
}
.info-block-container .content-block.selectable {
  cursor: pointer;
}
.info-block-container .content-block.selected {
  background: #cdced1;
}
.info-block-container .content-block dl {
  padding: 10px;
  margin-bottom: 5px;
  clear: both;
}
.info-block-container .content-block dt {
  width: 40%;
  vertical-align: top;
  text-align: right;
  margin-right: 6px;
  display: inline-block;
  line-height: 1.5em;
}
.info-block-container .content-block dd {
  line-height: 1.5em;
  display: inline-block;
  width: 55%;
  vertical-align: top;
}
.info-block-container .content-block dl.long-label dt {
  width: 65%;
}
.info-block-container .content-block dl.long-label dd {
  width: 30%;
}
.kru-form h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.form-row {
  padding-bottom: 16px;
}
.form-row .kru-form-input {
  height: 26px;
}
.kru-form-field-label {
  display: block;
}
.kru-form-field-label-text {
  display: inline-block;
  width: 140px;
  /*height: 26px;
	line-height: 26px;*/
  padding-right: 14px;
  padding-top: 6px;
  text-align: right;
  vertical-align: top;
  color: #575756;
}
.kru-form-field-label-text:first-letter {
  text-transform: uppercase;
}
/* Common Field Style */
.kru-form-field.disabled,
input:disabled {
  color: #8a8a89 !important;
}
.kru-form-input.empty-and-required,
.kru-form-input.invalid,
.kru-form-select.empty-and-required,
.kru-form-select.invalid,
.kru-form-textarea.empty-and-required,
.kru-form-textarea.invalid {
  border-color: red;
}
/* Specific Field Style */
.kru-form-input,
.kru-form-textarea {
  border: 1px solid #e4e4e4;
  color: #575756;
}
.kru-form-textarea {
  resize: vertical;
}
.kru-form-radio {
  display: inline-block;
  width: 200px;
}
.kru-form-radio label {
  display: block;
  margin-bottom: 4px;
}
.kru-form-radio label > span {
  display: inline-block;
  margin-left: 10px;
  color: #575756;
}
.kru-form-radio label input:not(:disabled) {
  cursor: pointer;
}
.kru-form-radio label input:focus + span {
  background-color: #9ad484;
}
input[type="color"] {
  box-shadow: none;
}
input[type="color"]:not(:disabled) {
  cursor: pointer;
}
input[type="file"] {
  /*border: 1px solid transparent;*/
}
input[type="file"].invalid:not(:disabled),
input[type="file"].empty-and-required:not(:disabled) {
  border-color: red;
  color: red;
}
.kru-form-field-label.datetime-picker {
  position: relative;
}
.kru-form-field-label.datetime-picker .datetime-popup {
  left: 140px;
  top: 30px;
}
.kru-form-field-label.datetime-picker .form-field-tooltip {
  visibility: hidden;
}
.datapoint-field {
  width: 200px;
}
.datapoint-field .items {
  padding-bottom: 16px !important;
}
.datapoint-field.empty-and-required .select2-selection,
.datapoint-field.invalid .select2-selection {
  border-color: red;
}
/* Not used in this Project maybe from another Module? */
.form-info-text {
  font-size: 12px;
  color: #575756;
}
.form-info-text span {
  font-weight: bold;
}
.tobigmessage {
  display: none;
}
.kru-form .filetobig .tobigmessage {
  display: inline-block;
  color: #E50000;
}
.form-row.hidden-label-text .kru-form-field-label-text {
  display: none;
}
.icon-select {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 26px;
  vertical-align: middle;
}
.icon-select.disabled {
  pointer-events: none;
}
.icon-select.disabled > img {
  opacity: 0.6;
}
.icon-select > img {
  height: 26px;
  width: 26px;
}
.icon-select > input {
  height: 26px;
}
.icon-select .dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 100;
  border: 1px solid #bdbdbc;
  background-color: white;
}
.icon-select .dropdown ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 5px;
}
.icon-select .dropdown ul li {
  width: 20%;
  padding: 2px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}
.icon-select .dropdown ul li img {
  width: 100%;
  height: 100%;
}
.icon-select .dropdown ul li:hover {
  border-color: #86cb6b;
  background-color: #c4e6b7;
}
.icon-select .dropdown ul li.selected {
  border-color: #6BC04B;
  background-color: #a9da97;
}
.multi-checkbox-panel {
  display: inline-block;
  border: 1px solid #bdbdbc;
}
.multi-checkbox-panel ul {
  width: 198px;
  height: 104px;
  padding: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  /*border-bottom: 1px solid @grey4;*/
}
.multi-checkbox-panel ul > li {
  width: 100%;
}
.multi-checkbox-panel ul > li:not(:last-child) {
  margin-bottom: 2px;
}
.multi-checkbox-panel ul > li label {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}
.multi-checkbox-panel button {
  width: 100%;
  margin-top: 10px;
}
.form-field-tooltip {
  display: inline;
  position: absolute;
  margin-left: 10px;
  z-index: -1000;
  width: 230px;
  opacity: 0;
  transition: opacity 0.1s linear, z-index 0s linear 0.1s;
  padding: 5px;
  border: 1px solid #bdbdbc;
  background: #575756;
  text-align: left;
  line-height: 1.2;
  color: #FFFFFF;
}
.form-field-tooltip .client-validation-texts {
  margin-top: 10px;
  font-size: 12px;
}
.form-field-tooltip .client-validation-texts li.not-checked {
  color: gray;
}
.form-field-tooltip .client-validation-texts li.valid {
  color: #6BC04B;
}
.form-field-tooltip .client-validation-texts li.invalid {
  color: red;
}
.form-field-tooltip .server-validation-texts {
  font-size: 12px;
  color: #cc0000;
}
.form-field-tooltip:after {
  content: " ";
  position: absolute;
  top: 5px;
  left: -10px;
  width: 0;
  height: 0;
  right-left: -10px;
  border-bottom: solid transparent 10px;
  border-right: solid #575756 10px;
  border-top: solid transparent 10px;
}
.kru-form-field:focus + .form-field-tooltip,
.kru-form-field.focus + .form-field-tooltip,
.select2-container--focus + .form-field-tooltip,
.select2-container--open + .form-field-tooltip {
  z-index: 10;
  opacity: 1;
}
.kru-form-field-label:hover .kru-form-field:not(.disabled):not(:focus):not(.focus) + .form-field-tooltip,
.select2-container:hover + .form-field-tooltip {
  z-index: 10;
  opacity: 1;
  transition: opacity 0.5s linear 0.5s;
}
.form-field-tooltip.forced-visible {
  z-index: 10;
  opacity: 1;
}
.kru-form-input,
.kru-form-textarea {
  width: 200px;
  color: #575756;
  background-color: #e4e4e4;
  padding: 5px;
  text-align: left;
  font-size: 12px;
}
.kru-form-input:focus,
.kru-form-textarea:focus {
  background-color: #9ad484;
}
.kru-form-input {
  height: 30px;
}
.kru-form-input-small {
  width: 100px;
}
.kru-form-input-big {
  width: 260px;
}
.kru-form-input-large {
  width: 340px;
}
.kru-form-input-extra-large {
  width: 460px;
}
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  top: 3px;
  padding-left: 25px;
  cursor: pointer;
  font-size: 17px;
  color: #575756;
  user-select: none;
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 2px solid #575756;
  background: #FFFFFF;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 5px;
  left: 4px;
  font-size: 13px;
  line-height: 0.8;
  color: #575756;
  transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bdbdbc !important;
  background-color: #e7ebef;
  cursor: default;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #bdbdbc;
}
[type="checkbox"]:disabled + label {
  color: #bdbdbc;
  cursor: default;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  background-color: #9ad484;
}
/* hover style just for information */
label:hover:before {
  border: 2px solid #8a8a89 !important;
}
.select2-source.small-select,
.small-select {
  width: 100px;
}
.select2-source {
  width: 200px;
  height: 28px;
  visibility: hidden;
}
select.kru-form-field {
  width: 200px;
}
span.select2-selection {
  border-radius: 0 !important;
  background-color: #e4e4e4 !important;
  color: #575756 !important;
}
span.select2-results li.select2-results__option--highlighted[aria-selected] {
  background-color: #86cb6b;
}
span.select2-container--open {
  z-index: 9999999;
}
.select2-container--focus:not(.select2-container--disabled) span.select2-selection,
.select2-container--open:not(.select2-container--disabled) span.select2-selection {
  background-color: #9ad484 !important;
}
select.empty-and-required + .select2-container span.select2-selection,
select.invalid + .select2-container span.select2-selection {
  border-color: red !important;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #a0a0a0 !important;
}
.select2-container--disabled {
  pointer-events: none;
}
.select2-container--disabled .select2-selection__rendered {
  color: #8a8a89 !important;
}
.select2-results__option:first-letter {
  text-transform: uppercase;
}
.kru-form-select {
  height: 26px;
  border: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  color: #575756;
}
.kru-form-select:focus option {
  background-color: #9ad484;
}
optgroup,
.select2-results__group {
  font-weight: bold;
}
.kru-form-field-label .slider {
  display: inline-block;
  position: relative;
  width: 200px;
}
.slider {
  height: 40px;
}
.slider .track-container {
  position: relative;
  height: 20px;
  margin: 0 10px 0 10px;
}
.slider .track-container .track {
  position: absolute;
  top: 9px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
}
.slider .track-container .track-fill {
  position: absolute;
  top: 9px;
  left: 0;
  height: 4px;
  border-radius: 4px;
  background-color: #575756;
}
.slider .track-container .thumb {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  border: 1px solid gray;
  border-radius: 100%;
  background-color: lightgray;
  cursor: pointer;
}
.slider .track-container .value {
  display: inline-block;
  position: absolute;
  bottom: -18px;
  left: -90px;
  width: 200px;
  text-align: center;
  font-size: 12px;
  color: #575756;
}
.slider:focus .track-container .thumb {
  background-color: #9ad484;
}
.slider.disabled {
  pointer-events: none;
}
.slider.disabled .track-container .track-fill {
  background-color: #a4a4a2;
}
.slider.disabled .track-container .thumb {
  background-color: #ffffff;
  border-color: #b3b3b3;
  cursor: default;
}
.slider.disabled .track-container .value {
  color: #8a8a89;
}
.reversed-checkbox-fieldset {
  border: 2px solid #575756;
  padding: 8px;
}
.reversed-checkbox-fieldset legend {
  color: #575756;
  padding: 5px;
  font-size: 1.2em;
  font-weight: bold;
}
.reversed-checkbox-fieldset label.checkbox-label {
  color: #575756;
  display: inline-block;
}
.reversed-checkbox-fieldset input[type="checkbox"]:disabled + label.checkbox-label {
  color: #cdced1;
}
.login-layout-container {
  height: auto;
  min-height: 100%;
  min-width: 532px;
  background: #6BC04B;
  overflow: hidden;
}
.login-layout-container div#layout-header {
  width: 100%;
  background: white;
}
.login-layout-container div#layout-header .session-box {
  display: none;
}
.login-layout-container div#layout-header div.logo {
  left: 66px;
}
.login-layout-container div#layout-content {
  width: 100%;
}
.login-layout-container div#layout-content div#login-container {
  margin-top: 9%;
}
.login-layout-container div#layout-content div#login-icon-gradient {
  position: relative;
  margin-left: auto ;
  margin-right: auto ;
  height: 52px;
  width: 106px;
  margin-top: -50px;
  transform: rotate(41deg);
  left: 37px;
  bottom: 26px;
  background: linear-gradient(90deg, #6BC04B 0%, #54a038 42%, #6BC04B 100%);
}
.login-layout-container div#layout-content div#login-icon {
  position: relative;
  border-radius: 50px;
  background-color: #575756;
  width: 100px;
  height: 100px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  z-index: 3;
}
.login-layout-container div#layout-content div#login-icon svg {
  width: 46px;
  height: 46px;
  margin-top: 23px;
  color: #FFFFFF;
}
.login-layout-container div#layout-content div.login-box {
  position: relative;
  padding: 0;
  margin-left: auto ;
  margin-right: auto ;
  height: 370px;
  width: 330px;
}
.login-layout-container div#layout-content div.login-box div.content {
  position: relative;
  height: 100%;
  background-color: #e7ebef;
  border-radius: 5px;
  padding: 30px 20px 10px 20px;
  margin-top: -38px;
  margin-bottom: 0;
  z-index: 2;
}
.login-layout-container div#layout-content div.login-box div.content p {
  font-size: 20px;
  color: #71716f;
  text-align: center;
  padding-top: 46px;
  padding-bottom: 35px;
}
.login-layout-container div#layout-content div.login-box div.content form input,
.login-layout-container div#layout-content div.login-box div.content form button {
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  color: #575756;
  background-color: #cdced1;
  border: none;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}
.login-layout-container div#layout-content div.login-box div.content form input:focus {
  background-color: #9ad484;
}
.login-layout-container div#layout-content div.login-box div.content form button {
  background-color: #6BC04B;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 29px;
  height: 50px;
  transition: 4s;
}
.login-layout-container div#layout-content div.login-box div.content form button.login-failed {
  transition: 0.3s;
  background-color: red;
}
.login-layout-container div#layout-content div.login-box div.content a {
  color: #575756;
  font-size: 14px;
  font-style: italic;
}
.login-layout-container div#layout-content div.login-box::before {
  content: "";
  position: absolute;
  height: 495px;
  width: 434px;
  background: linear-gradient(90deg, #54a038 1%, #6BC04B 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  margin-left: auto ;
  margin-right: auto ;
  top: 80px;
  left: 114px;
  transform: rotate(41deg);
  z-index: 1;
}
#layout-footer.layout-login-footer {
  background: transparent;
}
.user-table th {
  width: 20%;
}
p.text-seperator {
  margin-top: 20px;
}
p.top-description {
  margin-bottom: 20px;
}
.user-group-table th {
  width: 50%;
}
.tenant-content {
  position: relative;
  height: 650px;
}
.tenant-content.tenant-consumption .ranking {
  display: none;
}
.tenant-content.tenant-ranking .billing-period-row {
  display: none;
}
.tenant-content .current-value-row {
  display: none;
}
.tenant-content-widget.interaction-disabled {
  pointer-events: none;
}
.tenant-content-widget.tenant-content-widget-scaled-up .period-selection {
  display: block;
}
.tenant-content-widget.tenant-content-widget-scaled-up .zoom-out-button {
  display: block;
}
.tenant-content-widget-scaled-up.zoom-out-button {
  display: inline;
}
.tenant-content-widget {
  position: absolute;
  background-color: white;
}
.tenant-content-widget .head {
  width: 100%;
  position: relative;
}
.tenant-content-widget .head .head-icon {
  position: absolute;
  z-index: 2;
}
.tenant-content-widget .head .head-bar {
  left: 0px;
  top: 0px;
  width: 100%;
  padding-left: 50px;
  z-index: 1;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  min-height: 30px;
  max-height: 60px;
  background-color: #575756;
}
.tenant-content-widget .head .head-bar .headline {
  vertical-align: middle;
  line-height: 30px;
  color: white;
  font-size: 25px;
  margin-left: 10px;
}
.tenant-content-widget .head .head-bar .browse-back-button,
.tenant-content-widget .head .head-bar .browse-forward-button,
.tenant-content-widget .head .head-bar .zoom-out-button {
  width: 18px;
}
.tenant-content-widget .body {
  width: 100%;
}
.tenant-content-widget .body .big-column {
  float: left;
  height: 100%;
}
.tenant-content-widget .body .small-column {
  float: left;
  height: 100%;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table {
  padding: 5px;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table table tr:not(:first-child) td {
  padding-top: 10px;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table table tr td {
  font-size: 12px;
  color: #575756;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table table tr td:first-child {
  padding-right: 5px;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table table .ranking .ranking-name {
  color: #575756;
  padding-top: 10px;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table table .ranking .ranking-value {
  color: #575756;
  padding-top: 5px;
  text-align: right;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table .info-symbol {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 2px;
  border: 1px solid #575756;
  border-radius: 7px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  cursor: default;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table .info-symbol .tooltip {
  left: -85px;
  bottom: 22px;
  width: 180px;
  pointer-events: none;
}
.tenant-content-widget .body .small-column .tenant-additional-info-table .info-symbol .tooltip:after {
  content: " ";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #575756 10px;
}
.tenant-content-widget .body .graph-controls {
  padding-top: 10px;
}
.tenant-content-widget .body .graph-controls input {
  position: relative;
}
.tenant-content-widget .body .graph-controls label {
  padding-top: 3px;
  display: block;
  line-height: 1.2;
  font-size: 12px;
  color: #575756;
}
.tenant-content-widget .body .graph-controls .tooltip {
  display: inline-block;
  position: relative;
}
.tenant-content-widget .body .graph-controls .tooltip .tooltip-content {
  width: 180px;
  line-height: 1.2;
  z-index: -1000;
  opacity: 0;
  transition: opacity 0.5s linear, z-index 0s linear 0.5s;
  display: inline;
  position: absolute;
  background: #575756;
  border: 1px solid #bdbdbc;
  color: #FFFFFF;
  padding: 5px;
  left: 30px;
  transform: translate(-30%, -80px);
}
.tenant-content-widget .body .graph-controls .tooltip .tooltip-content:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #575756 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}
.tenant-content-widget .body .graph-controls .tooltip:hover .tooltip-content {
  opacity: 1;
  z-index: 10;
  transition: opacity 0.5s linear;
}
.tenant-content-widget .body .graph {
  width: 100%;
}
.tenant-content-widget .head-bar-button {
  display: inline;
  float: right;
  margin-top: 5px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.tenant-content-widget .head-bar-button:hover {
  cursor: pointer;
}
.tenant-content-widget .scale-button {
  width: 20px;
  height: 20px;
}
.tenant-content-widget .scale-button div {
  display: none;
}
.tenant-content-widget .scale-button div.active {
  /* IE HACK to have display set to 'inline' if 'initial' is not supported */
  display: inline;
  display: initial;
}
.tenant-content-widget .quantity-type-button {
  width: 40px;
  height: 20px;
}
.tenant-content-widget .quantity-type-button div {
  transition: background-color 1s;
  transform: translateY(-1px);
  width: 20px;
  height: 20px;
  float: left;
  width: 50%;
  border: solid white 1px;
}
.tenant-content-widget .quantity-type-button div.active {
  background-color: #6BC04B;
}
.tenant-content-widget .zoom-out-button {
  display: none;
}
.tenant-content-widget .period-selection {
  float: right;
  margin-right: 20px;
  display: none;
}
.tenant-content-widget .datetime-picker {
  display: inline-block;
  float: right;
}
.tenant-content-widget .datetime-input {
  float: right;
  width: 120px;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  border: none;
  background-color: #e4e4e4;
  color: #575756;
}
.tenant-ranking .graph-controls {
  display: none;
}
.histogram .histogram-axis .domain {
  fill: none;
  stroke: #575756;
}
.histogram .histogram-axis .tick text {
  font-size: 10px;
}
.histogram .histogram-bar {
  stroke: none;
}
.chart-tooltip {
  padding: 3px;
  line-height: 16px;
  background-color: #575756;
  text-align: center;
  font-size: 12px;
  color: white;
  pointer-events: none;
  opacity: 0.3;
  border: 1px solid grey;
}
.graph .axis .domain {
  fill: none;
  stroke: #575756;
}
.graph .axis .tick text {
  font-size: 10px;
  fill: #575756;
  stroke: none;
}
.manage-messages-page {
  color: #575756;
}
.manage-messages-page .left-column {
  float: left;
  width: 40%;
  padding-right: 20px;
}
.manage-messages-page .right-column {
  float: left;
  width: 60%;
  padding-left: 20px;
}
.manage-messages-page .message-table .date-col {
  width: 33.33333333%;
}
.manage-messages-page .message-table .subject-col {
  width: 50%;
}
.manage-messages-page .message-table .attachment-col {
  width: 16.66666667%;
}
.manage-messages-page .message-table tr.new-message {
  font-weight: bold;
}
.manage-messages-page .message {
  margin-top: 50px;
}
.manage-messages-page .message .message-subject {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.manage-messages-page .message .message-date {
  margin-top: 20px;
  font-weight: bold;
}
.read-message-dialog {
  width: 600px;
}
.read-message-dialog .dialog-body {
  color: #575756;
}
.read-message-dialog .dialog-body .message-subject {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.read-message-dialog .dialog-body .message-date {
  margin-top: 20px;
  font-weight: bold;
}
.write-message-dialog {
  width: 600px;
}
.write-message-dialog .tree-container {
  display: inline-block;
  width: 360px;
}
.write-message-dialog .form-row .kru-form-field {
  width: 370px;
}
.object-heading-label {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #6BC04B;
  text-transform: uppercase;
}
.object-heading-value {
  font-size: 18px;
  font-weight: bold;
  color: #575756;
}
#sub-content {
  position: relative;
  margin-top: 40px;
  min-height: 300px;
}
.virtual-meter-connection-table,
.lo-ra-value-config-dialog table {
  border-left: 1px solid #575756;
  border-right: 1px solid #575756;
}
.virtual-meter-connection-table thead tr,
.lo-ra-value-config-dialog table thead tr {
  height: 30px;
}
.virtual-meter-connection-table thead th,
.lo-ra-value-config-dialog table thead th {
  text-align: center !important;
  padding: 0 !important;
}
.virtual-meter-connection-table thead th object,
.lo-ra-value-config-dialog table thead th object {
  width: 25px;
  height: 25px;
  margin-top: 2px;
}
.virtual-meter-connection-table tr,
.lo-ra-value-config-dialog table tr {
  border-bottom: 1px solid #575756;
}
.virtual-meter-connection-table tbody td,
.lo-ra-value-config-dialog table tbody td {
  padding: 4px 4px 4px 4px !important;
  cursor: default;
}
.virtual-meter-connection-table tbody td.first-column-cell,
.lo-ra-value-config-dialog table tbody td.first-column-cell {
  text-align: left;
}
.virtual-meter-connection-table tbody td.factor-cell,
.lo-ra-value-config-dialog table tbody td.factor-cell,
.virtual-meter-connection-table tbody td.factor-sum-cell,
.lo-ra-value-config-dialog table tbody td.factor-sum-cell {
  text-align: center;
}
.virtual-meter-connection-table tbody td.factor-cell input,
.lo-ra-value-config-dialog table tbody td.factor-cell input,
.virtual-meter-connection-table tbody td.factor-sum-cell input,
.lo-ra-value-config-dialog table tbody td.factor-sum-cell input {
  text-align: right;
}
.virtual-meter-connection-table tbody td.factor-divided-evenly-cell,
.lo-ra-value-config-dialog table tbody td.factor-divided-evenly-cell {
  text-align: center;
}
.virtual-meter-connection-table tbody td.factor-divided-evenly-cell label,
.lo-ra-value-config-dialog table tbody td.factor-divided-evenly-cell label {
  position: relative;
  left: 3.7px;
}
.virtual-meter-connection-table tbody .factor-sum-text-field,
.lo-ra-value-config-dialog table tbody .factor-sum-text-field {
  cursor: default;
  pointer-events: none;
}
.virtual-meter-connection-table tbody .heading-cell:first-letter,
.lo-ra-value-config-dialog table tbody .heading-cell:first-letter {
  text-transform: uppercase;
}
.virtual-meter-connection-table .info-symbol,
.lo-ra-value-config-dialog table .info-symbol {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #575756;
  border-radius: 8px;
  font-size: 12.8px;
  font-weight: bold;
  cursor: default;
}
.virtual-meter-connection-table .info-symbol .tooltip,
.lo-ra-value-config-dialog table .info-symbol .tooltip {
  left: -152px;
  top: 26px;
  width: 320px;
  font-size: 12px;
  pointer-events: none;
}
.virtual-meter-connection-table .info-symbol .tooltip:after,
.lo-ra-value-config-dialog table .info-symbol .tooltip:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: -10px;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #575756 10px;
}
.virtual-meter-connection-table input,
.lo-ra-value-config-dialog table input,
.virtual-meter-connection-table select,
.lo-ra-value-config-dialog table select {
  width: 55px;
  padding-right: 4px;
  padding-left: 4px;
  border: 1px solid gray;
  color: #575756;
}
.edit-virtual-meter-connections-dialog {
  width: 800px;
}
table.element-template-table > tbody > tr > td:first-child {
  width: 40px;
}
table.element-template-table > tbody > tr > td:first-child img {
  height: 20px;
}
.element-template-form input[type=file].kru-form-field {
  width: 120px;
  border: 1px solid transparent;
  color: transparent;
}
.element-template-form input[type=file].kru-form-field.invalid,
.element-template-form input[type=file].kru-form-field.empty-and-required {
  border-color: #E50000;
}
div.host-connection-type-fs-hidden {
  display: none;
}
div.host-connection-type-fs {
  display: block;
}
table.secombo-mobile-number-table td {
  cursor: default;
}
.invoice-item-group {
  margin-bottom: 10px;
  vertical-align: middle;
}
.invoice-item-group.selected {
  background-color: #6BC04B !important;
}
.invoice-item-group:hover {
  background-color: #f6f6f6;
}
.invoice-item {
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: middle;
}
.invoice-item.selected {
  background-color: #6BC04B !important;
}
.invoice-item:hover {
  background-color: #ececec;
}
.item-order {
  position: absolute;
  right: -40px;
  display: none;
}
.item-order div {
  position: relative;
  top: -30px;
}
.threshold-table th {
  width: 20%;
}
.threshold-violation-table th {
  width: 25%;
}
#pickElementTypeDialog .kru-form-field-option-label {
  display: block;
  margin-top: 6px;
}
div#measurementPointDialog input.clickable:not(.disabled),
div#sumDialog input.clickable:not(.disabled) {
  cursor: pointer;
}
div#formulaDialog .formula-validation-result .success {
  color: #44BA00;
}
div#formulaDialog .formula-validation-result .pending {
  color: #575756;
  background: url("/images/gif/spinner.png.gif") no-repeat;
  background-size: 1em 1em;
  padding-left: 17px;
}
div#formulaDialog .formula-validation-result .failure {
  color: #E50000;
}
#time-selection {
  color: #575756;
  /*label {
		display: block;
		margin: 2px 0 2px 0;
		
		span {
			display: inline-block;
			width: 50px;
		}
	}*/
}
#chart-table th {
  width: 25%;
}
.interval-dialog b {
  display: inline-block;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 2px;
  color: #575756;
}
.interval-dialog b:first-letter {
  text-transform: uppercase;
}
.interval-dialog .interval-row {
  margin-bottom: 2px;
}
.interval-dialog .interval-row label {
  display: inline-block;
  position: relative;
  margin-right: 5px;
}
.interval-dialog .interval-row label input {
  height: 25px;
}
.interval-dialog .interval-row button {
  position: relative;
  top: -1px;
  height: 25px;
}
.interval-dialog > button {
  display: block;
}
.charting .content-head {
  height: 28px;
}
.charting .content-head > * {
  height: 100% !important;
}
.charting .content-head .select2 {
  float: left;
}
.charting .content-head .favinfo-table {
  margin-left: 10px;
  float: left;
}
.charting .content-head .favinfo-table td {
  margin: auto;
}
.charting .content-head .favinfo-table td:first-child {
  padding-right: 10px;
}
.charting .content-head .favinfo-table .name-display {
  text-align: center;
  font-weight: bold;
}
.charting .content-head .toolbar {
  float: right;
}
.charting #chart-container {
  margin-top: 10px;
}
#toolbar-container > * {
  height: 100%;
}
/*
 * Standalone charts on a blank page e.g. for PhantomJS
 */
.charting-standalone #toolbar-container {
  /* hide interactive stuff */
  display: none;
}
.charting-standalone #info-headline-container tr {
  height: 40px;
}
.charting-standalone #info-headline-container tr th {
  vertical-align: middle;
}
.charting-standalone #info-headline-container tr th.main-period-col {
  text-align: right;
}
.auto-report-filetype-row .settings-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: bottom;
}
#factor-value-table {
  margin-top: 20px;
}
.lo-ra-value-config-dialog table {
  border-top: 1px solid #575756;
}
.lo-ra-value-config-dialog table th {
  vertical-align: middle !important;
  font-weight: bold;
  color: #575756;
}
.lo-ra-value-config-dialog table th:first-letter {
  text-transform: uppercase;
}
.lo-ra-value-config-dialog table input {
  width: 100%;
}
.lo-ra-value-config-dialog table select {
  width: 100%;
}
.lo-ra-value-config-dialog table td button {
  margin-left: 2px;
}
.lo-ra-value-config-dialog table tr.highlighted {
  background-color: yellow;
}
.lo-ra-value-config-dialog table tr {
  transition: background-color 1s;
}
.lo-ra-value-config-dialog tr.sortable-item-row input[type="text"] {
  height: 26px;
  vertical-align: baseline;
}
.lo-ra-value-config-dialog tr.sortable-item-row select {
  vertical-align: baseline;
  height: 27px;
}
.csv-import-dialog {
  width: 600px;
}
.csv-import-dialog .dialog-body {
  /* Heading already has margin top, too much spacing */
  padding-top: 0px;
}
.csv-import-form .csv-import-explanation {
  margin-bottom: 10px;
}
.csv-import-form .csv-import-explanation p {
  margin-top: 5px;
  line-height: 1.25;
}
.csv-import-form .csv-import-explanation strong {
  display: inline-block;
  font-weight: bold;
  color: #575756;
  padding: 15px 0 0 0;
}
.csv-import-form .csv-import-explanation table th {
  background-color: #e7ebef;
  padding: 2px;
  border: 1px #575756 solid;
}
.csv-import-form .csv-import-explanation table td {
  padding: 2px;
  border: 1px #575756 solid;
}
.csv-import-form .csv-import-example {
  margin-top: 10px;
}
.csv-import-form .form-row {
  margin-top: 10px;
}
.deactivated-datasource-tooltipp {
  margin-top: -8px;
}
textarea.help-text-textarea {
  width: 400px;
  height: 400px;
}
.twoColumnLayout .left-container {
  float: left;
  position: relative;
  width: 50%;
}
.twoColumnLayout .right-container {
  float: left;
  position: relative;
  width: 50%;
  padding-left: 10px;
  min-height: 80px;
}
.twoColumnLayout:after {
  content: "\00A0";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
div#help-text-form-container .form-field-tooltip {
  display: none;
}
div#help-text-markdown-preview-container {
  margin-top: 30px;
  background-color: #e7ebef;
  padding: 10px;
}
div.email-config-form-container {
  margin-top: 20px;
  margin-bottom: 40px;
}
div.email-config-form-container .email-text-config-field {
  width: 600px;
}
div.email-config-form-container textarea.email-text-config-field {
  height: 300px;
}
.float-left-panel {
  display: inline-block;
  display: block;
}
.float-left-panel > * {
  float: left;
}
.plain-form-container {
  position: relative;
  margin-right: 40px;
  padding: 20px 20px 60px 20px;
  border: 1px solid grey;
}
.plain-form-container > h3 {
  margin-top: 0;
  margin-bottom: 50px;
}
.plain-form-container > button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.plain-form-container > .button-panel {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: inline-block;
  display: block;
}
.plain-form-container > .button-panel > button {
  float: right;
  margin-left: 20px;
}
.plain-form-container > form > label {
  display: block;
  margin-bottom: 20px;
}
.plain-form-container > form > label > span:first-child {
  text-align: left;
}
#backup-settings-form {
  display: inline-block;
  min-width: 600px;
}
table.partition-table td {
  padding: 5px;
  padding-left: 10px;
}
div.monitoring-info-item {
  width: 415px;
  background: #ececec;
  padding: 10px;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: top;
  min-height: 60px;
}
div.monitoring-info-item .info-label {
  margin-bottom: 5px;
}
div.monitoring-info-item .progress-container {
  min-height: 20px;
}
.error-messages-container .user-group {
  display: inline-block;
  padding: 10px;
  background: #ececec;
  min-width: 250px;
  text-align: center;
}
#system-update-table th {
  width: 50%;
}
#system-update-table tr.running {
  color: steelblue;
}
.license-overview-container .install-id {
  display: inline-block;
  padding: 10px;
  background: #ececec;
  min-width: 250px;
  text-align: center;
}
.license-overview-container .content-block.valid .block-header {
  background: #6BC04B;
}
.license-overview-container .content-block.expired .block-header {
  background: darkorange;
}
.license-overview-container .content-block.invalid .block-header {
  background: #E50000;
}
.dashboard {
  position: relative;
  left: -18px;
  width: calc(100% +  36px);
}
.dashboard .box-container {
  position: absolute;
  padding: 18px;
}
.dashboard .box-container.dragged,
.dashboard .box-container.resized {
  z-index: 1000;
}
.dashboard .preview-box {
  position: absolute;
  background-color: #6BC04B;
  opacity: 0.3;
}
.dashboard .box {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid grey;
  background-color: white;
}
.dashboard .box .resize-zone {
  position: absolute;
  z-index: 50;
}
.dashboard .box .resize-zone.east {
  right: -5px;
  top: 5px;
  width: 10px;
  height: calc(100% -  10px);
  cursor: e-resize;
}
.dashboard .box .resize-zone.west {
  left: -5px;
  top: 5px;
  width: 10px;
  height: calc(100% -  10px);
  cursor: w-resize;
}
.dashboard .box .resize-zone.north {
  left: 5px;
  top: -5px;
  width: calc(100% -  10px);
  height: 10px;
  cursor: n-resize;
}
.dashboard .box .resize-zone.south {
  left: 5px;
  bottom: -5px;
  width: calc(100% -  10px);
  height: 10px;
  cursor: s-resize;
}
.dashboard .box .head {
  height: 30px;
  padding: 0 10px 0 10px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 16px;
  border-bottom: 1px solid grey;
  cursor: move;
}
.dashboard .box .head .buttons {
  float: right;
  height: 100%;
  min-width: 10px;
  cursor: default;
}
.dashboard .box .head .buttons > button {
  float: right;
  width: 23px;
  height: 23px;
  margin: 2px 0 2px 0;
  padding: 0;
  background-color: transparent;
}
.dashboard .box .head .buttons > button:not(:first-child) {
  margin-right: 10px;
}
.dashboard .box .head .buttons > button > svg {
  width: 100%;
  height: 100%;
}
.dashboard .box .body {
  position: relative;
  width: 100%;
  height: calc(100% - 30px);
}
.dashboard .box .body .content-container {
  width: 100%;
  height: 100%;
}
.dashboard .box .body .content-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dashboard .box.iframe-box .body iframe {
  width: 100%;
  height: 100%;
}
.dashboard .box.chart-box img {
  width: 100%;
  height: 100%;
}
.dashboard .box.datapoint-state-table-box .content-container {
  overflow-y: auto;
}
.dashboard .box.datapoint-state-table-box .content-container table tbody tr {
  height: 25px;
}
.dashboard .box.datapoint-state-table-box .content-container table tbody tr td {
  padding-right: 10px;
}
.dashboard .box.datapoint-state-table-box .content-container table tbody tr:nth-child(odd):not(.empty-table-message-row) {
  background-color: #ececec;
}
