.user-table {

	th {
		width: (100%) / 5;
	}
}

p.text-seperator {
	margin-top: 20px;
}

p.top-description {
	margin-bottom: 20px;
}
