.threshold-table {

	th {
		width: (100%) / 5;
	}
}

.threshold-violation-table {

	th {
		width: (100%) / 4;
	}
}
