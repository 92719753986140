.dashboard {
	@boxMargin: 18px;

	position: relative;
	left: -@boxMargin;
	width: calc(~"100% + " 2 * @boxMargin);

	.box-container {
		position: absolute;
		padding: @boxMargin;

		&.dragged, &.resized {
			z-index: 1000;
		}
	}

	.preview-box {
		position: absolute;
		background-color: @color_main;
		opacity: 0.3;
	}

	.box {
		@borderWidth: 2px;

		position: relative;;
		width: 100%;
		height: 100%;
		border: @borderWidth solid grey;
		background-color: white;

		.resize-zone {
			@width: 10px;

			position: absolute;
			z-index: 50;

			&.east {
				right: -@width / 2;
				top: @width / 2;
				width: @width;
				height: calc(~"100% - " @width);
				cursor: e-resize;
			}

			&.west {
				left: -@width / 2;
				top: @width / 2;
				width: @width;
				height: calc(~"100% - " @width);
				cursor: w-resize;
			}

			&.north {
				left: @width / 2;
				top: -@width / 2;
				width: calc(~"100% - " @width);
				height: @width;
				cursor: n-resize;
			}

			&.south {
				left: @width / 2;
				bottom: -@width / 2;
				width: calc(~"100% - " @width);
				height: @width;
				cursor: s-resize;
			}
		}

		.head {
			height: 30px;
			padding: 0 10px 0 10px;
			line-height: 30px;
			vertical-align: middle;
			font-size: 16px;
			border-bottom: 1px solid grey;
			cursor: move;

			.buttons {
				float: right;
				height: 100%;
				min-width: 10px;
				cursor: default;

				> button {
					float: right;
					width: 23px;
					height: 23px;
					margin: 2px 0 2px 0;
					padding: 0;
					background-color: transparent;

					&:not(:first-child) {
						margin-right: 10px;
					}

					> svg {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.body {
			position: relative;
			width: 100%;
			height: calc(~"100% - 30px");

			.content-container {
				width: 100%;
				height: 100%;
			}

			.content-overlay {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.box.iframe-box .body iframe {
		width: 100%;
		height: 100%;
	}

	.box.chart-box img {
		width: 100%;
		height: 100%;
	}

	.box.datapoint-state-table-box {
		.content-container {

			overflow-y: auto;

			table {
				tbody {
					tr {
						height: 25px;
						td {
							padding-right: 10px;
						}
					}

					tr:nth-child(odd):not(.empty-table-message-row) {
						background-color: @color_table_odd_row;
					}
				}
			}
		}
	}
}
