@tooltipBackgroundColor: @darkgrey;
@tooltipBorderColor: @grey4;
@tooltipNoseSize: 10px;

.form-field-tooltip {
	display: inline;
	position: absolute;
	margin-left: 10px;
	z-index: -1000;
	
	width: 230px;
	
	opacity: 0;
	transition: opacity 0.1s linear, z-index 0s linear 0.1s;
	
	padding: 5px;
	border: 1px solid @tooltipBorderColor;
	background: @tooltipBackgroundColor;
	text-align: left;
	line-height: 1.2;
	color: @white;
	
	.field-description {

	}
	
	.client-validation-texts {
		margin-top: 10px;
		font-size: 12px;
		
		li.not-checked {
			color: gray;
		}
		
		li.valid {
			color: @color_valid;
		}
		
		li.invalid {
			color: red;
		}
	}

	.server-validation-texts {
		font-size: 12px;
		color: #cc0000;
	}
	
	&:after {
		content: " ";
		position: absolute;
		top: 5px;
		left: -@tooltipNoseSize;
		width: 0;
		height: 0;
		right-left: -@tooltipNoseSize;
		border-bottom: solid transparent @tooltipNoseSize;
		border-right: solid @tooltipBackgroundColor @tooltipNoseSize;
		border-top: solid transparent @tooltipNoseSize;
	}
}

.kru-form-field:focus + .form-field-tooltip,
.kru-form-field.focus + .form-field-tooltip,
.select2-container--focus + .form-field-tooltip,
.select2-container--open + .form-field-tooltip,
/*.kru-form-field[type="checkbox"]:focus + label + .form-field-tooltip,*/
{
	z-index: 10;
	opacity: 1;
}

.kru-form-field-label:hover .kru-form-field:not(.disabled):not(:focus):not(.focus) + .form-field-tooltip,
.select2-container:hover + .form-field-tooltip
{
	z-index: 10;
	opacity: 1;
	transition: opacity 0.5s linear 0.5s;
}

.form-field-tooltip.forced-visible {
	z-index: 10;
	opacity: 1;
}
