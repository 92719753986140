div.host-connection-type-fs-hidden {
	display: none;
}

div.host-connection-type-fs {
	display: block;
}

table.secombo-mobile-number-table {
	td {
		cursor: default;
	}
}
