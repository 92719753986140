
.kru-form-input, .kru-form-textarea {
	width: 200px;
	color: @darkgrey;
	background-color: @grey6;
	padding: 5px;
	text-align: left;
	font-size: 12px;

	&:focus {
		background-color: @color_main_focus;
	}
}

.kru-form-input {
	height: 30px;
}

.kru-form-input-small {
	width: 100px;
}

.kru-form-input-big {
	width: 260px;
}

.kru-form-input-large {
	width: 340px;
}

.kru-form-input-extra-large {
	width: 460px;
}
