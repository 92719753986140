.tenant-content {
	position: relative;
	height: 650px;

	&.tenant-consumption {
		.ranking {
			display: none;
		}
	}
	
	&.tenant-ranking {
		.billing-period-row {
			display: none;
		}
	}
	
	.current-value-row {
		display: none;
	}
}

.tenant-content-widget.interaction-disabled {
	pointer-events: none;
}

.tenant-content-widget.tenant-content-widget-scaled-up {
	.period-selection {
		display: block;
	}
	.zoom-out-button {
		display: block;
	}
}

.tenant-content-widget-scaled-up.zoom-out-button {
	display: inline;
}

.tenant-content-widget {

	position: absolute;
	background-color: white;
		
	.head {
		width: 100%;
		position: relative;
		
		.head-icon {
			position: absolute;
			z-index: 2;
		}

		.head-bar {
			@headbar-offset: -7px;
			
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			padding-left: 50px;
			z-index: 1;
			border-top-left-radius: @content-widget-head-bar-height;
			border-bottom-left-radius: @content-widget-head-bar-height;
			
			.vertCentered();
			min-height: @content-widget-head-bar-height;
			max-height: 2*@content-widget-head-bar-height;
			background-color: @color_content_head;
			
			.headline {
				vertical-align:middle;
				line-height: @content-widget-head-bar-height;
				color: white;
				font-size: @headline-font-size;
				margin-left: 10px;
			}
			
			.browse-back-button, .browse-forward-button, .zoom-out-button {
				width: 18px;
			}
		}
	}
	
	.body {
		width: 100%;
			
		.big-column {
			float: left;
			height: 100%;
		}
		
		.small-column {
			float: left;
			height: 100%;

			.tenant-additional-info-table {
				padding: 5px;
				table {
					tr:not(:first-child) td {
						padding-top: 10px;
					}

					tr td {
						font-size: 12px;
						color: @darkgrey;

						&:first-child {
							padding-right:5px;
						}
					}
					.ranking {
						.ranking-name {
							color: @darkgrey;
							padding-top: 10px;
						}
						.ranking-value {
							color: @darkgrey;
							padding-top: 5px;
							text-align: right;
						}
					}
				}
				
				.info-symbol {
					display: inline-block;
					width: 14px;
					height: 14px;
					margin-left: 2px;
					border: 1px solid @darkgrey;
					border-radius: 7px;
					text-align: center;
					vertical-align: middle;
					font-weight: bold;
					cursor: default;
	
					.tooltip {
						@width: 180px;
						@noseSize: 10px;

						left: -@width / 2 + @noseSize / 2;
						bottom: 2 * @noseSize + 2px;
						width: @width;
						pointer-events: none;
						
						.tooltip-nose-bottom-mixin(@noseSize, @defaultTooltipBackgroundColor);
					}
				}
			}
		}

		.graph-controls {
			padding-top:10px;
			input {
				position: relative;
			}
			label {
				padding-top:3px;
				display: block;
				line-height: 1.2;
				font-size: 12px;
				color: @darkgrey;
			}
	
			.tooltip {
				display: inline-block;
				position: relative;
				
				.tooltip-content {
					width:180px;
					line-height:1.2;
					z-index: -1000;
					opacity: 0;
					transition: opacity 0.5s linear, z-index 0s linear 0.5s;
					display:inline;
					position:absolute;
					background: @darkgrey;
					border: 1px solid @grey4;
					color: @white;
					padding: 5px;
					left: 30px;
					
					transform: translate(-30%,-80px);
					
					&:after {
						border-left: solid transparent 10px;
						border-right: solid transparent 10px;
						border-top: solid @darkgrey 10px;
						bottom: -10px;
						content: " ";
						height: 0;
						left: 50%;
						margin-left: -13px;
						position: absolute;
						width: 0;
					}
				}
				
				&:hover .tooltip-content {
					opacity: 1;
					z-index: 10;
					transition: opacity 0.5s linear;
				}
			}
		}
		
		.graph {
			width: 100%;
		}
	}

	.head-bar-button {
		display: inline;
		float: right;
		margin-top: (@content-widget-head-bar-height - @content-widget-head-control-height) / 2;
		height: @content-widget-head-control-height;
		margin-left: 10px;
		margin-right: 10px;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		
		&:hover {
			cursor:pointer;
		}
	}
	
	.scale-button {
		width:20px;
		height:20px;
		div {
			display:none;
			&.active {
				/* IE HACK to have display set to 'inline' if 'initial' is not supported */
				display: inline;
				display: initial;
			}
		}
	}
	
	.quantity-type-button {
		width:40px;
		height:20px;
	
		div {
			transition: background-color 1s;
			transform:translateY(-1px);
			width:20px;
			height:20px;
			float:left;
			width:50%;
			border: solid white 1px;

			&.active {
				background-color: @color_quantity-type-button_active;
			}
		}
	}
	
	.zoom-out-button {
		display: none;
	}
	
	.period-selection {
		float: right;
		margin-right: 20px;
		display: none;
	}
	
	.datetime-picker {
		display: inline-block;
		float: right;
	}
	
	.datetime-input {
		float: right;
		width: 120px;
		height: @content-widget-head-control-height;
		margin-top: (@content-widget-head-bar-height - @content-widget-head-control-height) / 2;
		margin-right: 10px;
		margin-left: 10px;
		text-align: center;
		border: none;
		background-color: @grey6;
		color: @darkgrey;
	}
}

.tenant-ranking {
	.graph-controls { display:none; }
}
