/* arrows */
.arr{
	border:       solid @color_main;
	border-width: 0 .2em .2em 0;
	display:      inline-block;
	padding:      .20em;
}
.arr-right {transform:rotate(-45deg);}
.arr-left  {transform:rotate(135deg);}
.arr-up    {transform:rotate(-135deg);}
.arr-down  {transform:rotate(45deg);}
