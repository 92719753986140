.table {
	width: 100%;
	background-color: transparent;
	border-collapse: collapse;
	border-spacing: 0;
	
	thead {
		th {
			text-align: left;
			padding: 5px;
			padding-left: 10px;
			cursor: default;
			user-select: none;
			outline-style: none; /* IE */
			
			.first-letter-uppercase-mixin();
			
			&[data-sort-criteria] {
				cursor: pointer;
			}
			
			&.sorted {
				font-style: italic;
			}
	
			&.sorted-asc:after,
			&.sorted-desc:after {
				content: "";
				float: right;
				border-color: white;
				border-width: 0 .1em .1em 0;
				margin-right: 8px;
			}
	
			&.sorted-asc:after{
				margin-top: 7px;
				&:extend(.arr);
				&:extend(.arr-up);
			}
	
			&.sorted-desc:after {
				margin-top: 3px;
				&:extend(.arr);
				&:extend(.arr-down);
			}
		}
	}
	
	tbody {
		position: relative;
		
		tr {
			&:not(:last-child) {
				border-bottom: @table_row_seperating_border;
			}
			&:hover {
				color: @color_table_hover_text;
			}
			&.selected {
				background-color: @color_main !important;
				color: @white;
			}
			vertical-align: middle;
			
			&.selected {
				font-style: italic;
			}			
			td {
				vertical-align: middle;
			}
		}
		
		tr.empty-table-message-row {
			pointer-events: none;
			td {
				.first-letter-uppercase-mixin();
			}
		}
		
		tr[data-id] td {
			padding: 5px;
			padding-left: 10px;
			cursor: pointer;
		}
	}
	
	&.reloading {
		tbody {
			pointer-events: none;
		}
	}
	
	td.number-cell {
		text-align: right;
	}
	
	td.empty-cell {
		opacity: 0.6;
	}
}

.table.table1 {
	thead {
		&:after {
			content: "-";
			display: block;
			line-height: 2em;
			color: transparent;
		}
		th {
			font-size: 16px;
			color: @darkgrey;
			&.sorted {
				background-color: @color_main;
				color: @white;
			}
		}
	}
	tbody {
		tr {
			height: 25px;
			color: @darkgrey;

			&:hover {
				color: @color_table_hover_text;
			}
			
			&.selected {
				background-color: @color_content_head !important;
				color: @white;
			}
		}
		
		tr:nth-child(odd):not(.empty-table-message-row) {
			background-color: @color_table_odd_row;
		}
	}
}

table.table2 {
	thead {
		background-color: @darkgrey;
		tr {
			th {
				font-size: 14px;
				color: white;
			}
		}
	}
	tbody {
		tr {

			td {
				padding-left: 10px;
			}
		}
	}
}

.table2.rawvalues {
	tbody {
		tr {
			td {
				font-family: "Inconsolata", "Fira Mono", "Source Code Pro", Monaco, Consolas, "Lucida Console", monospace;
			}
		}
	}
}

.datetime-table-col-width {
	width: 150px;
}

.number-cell {
	text-align: right;
}
