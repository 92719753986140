/*
 * $Id$
 *
 * Name:      $HeadURL$
 * Author:    SVN: $Author$
 * Version:   SVN: $Rev$ $Date$
 * Copyright: 2015 krumedia GmbH (http://www.krumedia.com)
 * Link:      http://www.krumedia.com/ krumedia GmbH
 */

/* The arrays are defined in static-colors.less */
#colors {
    .-(@i: length(@color_names)) when (@i > 0) {
        @name: e(extract(@color_names, @i));
        @fullname: "color_@{name}";
        &.color_@{name} {color: @@fullname}
        .-((@i - 1));
    } .-;
}
#colors {
    .-(@i: length(@hover_color_names)) when (@i > 0) {
        @name: e(extract(@hover_color_names, @i));
        @fullname: "color_@{name}_hover";
        &.color_@{name}_hover {color: @@fullname}
        .-((@i - 1));
    } .-;
}
/* #colors {
    .-(@i: length(@additional_color_names)) when (@i > 0) {
        @name: e(extract(@additional_color_names, @i));
        @fullname: "@{name}";
        &.@{name} {color: @@fullname}
        .-((@i - 1));
    } .-;
}
 */